import {Popup, Tabs,Swiper} from 'antd-mobile'
import React, {useCallback, useEffect, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {useNavigate,Link,useBeforeUnload,useNavigationType} from 'react-router-dom'
import VideoCard from '@/components/VideoCard'
import {RootState} from "@/types/store";
import {changeMenuState, getUserInfo, registerUser, getIndexData, setHistory} from '@/store/actions/app'
import Header from '@/components/header'
import Footer from "@/components/Footer";
import Img from "@/components/Img";
import { Toast } from 'antd-mobile';
import {RightOutline} from 'antd-mobile-icons';
import { v4 as uuidv4 } from 'uuid';
import NProgress from "nprogress";
import { useActivate, useUnactivate, withActivation } from 'react-activation'

interface response {
    code: number;
    data: object;
    msg: string
}
interface home {
    column_list:Array<object>,
    banner_list:Array<object>
}
const Home = () => {
    const dispatch = useDispatch()
    const navigate =useNavigate()
    const navigationType =useNavigationType()
    const [list,bannerList] = useSelector(
        (state: RootState) => [state.app.indexData.column_list,state.app.indexData.banner_list]
    )
    useEffect(() => {
        Toast.show({
            icon: 'loading',
            content: 'loading…',
            duration:0
        })
        dispatch(getIndexData())
        /*NProgress.done()*/
        /*dispatch(setHistory(history.location.pathname))*/
    }, [])
    useEffect(() => {

    }, [bannerList])
    useActivate(()=>{
        if (navigationType!=='POP')
        {
            Toast.show({
                icon: 'loading',
                content: 'loading…',
                duration:0
            })
            dispatch(getIndexData())
        }
        else {
            window.scrollTo(0,0)
        }
    })
    return (
        <div className="arrdv9-0 kaxIlA cat-padding">
            <Header></Header>
            <Swiper autoplayInterval={5000} loop={true} autoplay={true} indicatorProps={{style:{
                    '--dot-color':'rgba(255,255, 255, 0.25)',
                    '--active-dot-color':'rgba(255,255, 255, 0.75)',
                    '--dot-size': '10px',
                    '--active-dot-size': '10px',
                    '--dot-border-radius': '50%',
                    '--active-dot-border-radius': '5px',
                    '--dot-spacing': '8px',
                }}} className="banner">{
                bannerList.map((item, index) => (
                    <Swiper.Item key={uuidv4()}>
                        <div
                            className="content"
                            onClick={() => {
                                window.open(item.redirect_url,'_blank')
                            }}
                        >
                            <Img  src={item.image_url}></Img>
                        </div>
                    </Swiper.Item>
                ))
            }</Swiper>
            {
                list.map((it:any,idx:number)=>{
                    return(
                        <div key={uuidv4()}>
                            <div className="sc-bSFVuW fNjRST">
                                <span className="sc-jvfriV kxdBff">{it.title}</span>
                                <Link to={`/column/${it.id}/${it.title}`} className="loader-more">查看更多<RightOutline fontSize={16} color={'#f94e9b'} /></Link>
                            </div>
                            <div className="sc-eCjkpP iDHATf">
                                <div className="sc-clhSjD yLshi">
                                    {
                                        it.video_list.map((item:any, index:number) => {
                                            return (
                                                <VideoCard info={item} key={uuidv4()}></VideoCard>
                                            )
                                        })
                                    }

                                </div>
                            </div>
                            <div className="sc-ksXhwv iVzzFR">
                                <Link to={`/column/${it.id}/${it.title}`} className="sc-gYhigD gdlclw">更多视频</Link>
                            </div>
                        </div>
                    )
                })
            }
            <Footer></Footer>
        </div>
    )
}

export default React.memo(Home)
