import {Base64} from "js-base64";

export const get_image=(buffer:any)=>{
    var binary = '';
    var url=''
    var bytes = new Uint8Array(buffer);
    for (var len = bytes.byteLength, i = 2; i < len; i++) {
        binary += String.fromCharCode(bytes[i]);
    }
    url="data:image/png;base64,"+Base64.btoa(binary);
    return url;
}
export function getUrlParam(name:string){
    let qs = arguments[1] || window.location.href,
        reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)"),
        r = qs.substr(qs.indexOf("?") + 1).match(reg);
    if(r !== null){
        let i = decodeURI(r[2]).indexOf('#');
        if(i !== -1) {
            return decodeURI(r[2]).substring(0,i);
        }else{
            return decodeURI(r[2]);
        };
    }else{
        return '';
    }
}
