import Service, { SuccessFormat } from './base'
import {getToken } from "@/utils/storage";
import Cookies from 'js-cookie';
import {getUrlParam} from '@/utils/common';
let domain=window.location.protocol+'//' + window.location.host
const api=Cookies.get('api')
class AppSDK extends Service {
    constructor () {
        super({
            baseURL:process.env.NODE_ENV=='development'?'https://ts-smhub.yatelan.cn/api':(api?api:'/api')
        })
    }

    /**
     * 获取分类列表
     * @param {String} tab 类型
     * @param {Number} page 页码
     * @param {Number} limit 每页数量
     */
    getTopicsByTab (tab: string, page: number = 1, limit: number = 20): Promise<SuccessFormat> {
        return this.get('/topics', {
            page: page,
            limit: limit,
            tab
        })
    }

    /**
     * 获取话题的文章详情
     * @param {String} topicId
     */
    getTopicDetail (topicId: string | number): Promise<SuccessFormat> {
        return this.get(`/topic/${topicId}`)
    }

    /**
     * 获取用户详情页数据
     * @param {String} username
     */
    getUserDetail (username: string): Promise<SuccessFormat> {
        return this.get(`/user/${username}`)
    }

    /**
     * 用户注册
     */
     register(): Promise<SuccessFormat> {
        return this.post(`/user/register`,{
            domain:domain,
            u:getUrlParam('u')
        })
    }
    /**
     * 获取用户信息
     */
    getUserInfo(): Promise<SuccessFormat> {
        return this.post(`/user/info`,{token:getToken()})
    }
    /**
     * 获取首页数据
     */
    getIndexData(): Promise<SuccessFormat> {
        return this.post(`/home`)
    }
    /**
     * 获取首页数据
     */
    getCategoryList(): Promise<SuccessFormat> {
        return this.post(`/video/get-category`)
    }
    /**
     * 全局配置
     */
    getConfig(): Promise<SuccessFormat> {
        return this.post(`/config`)
    }
    /**
     * 图片解析
     */
    getImage(url:string): Promise<SuccessFormat> {
        return this.postImg(url)
    }
    /**
     * 获取视频信息
     */
    getVideoInfo(id:any): Promise<SuccessFormat> {
        return this.post(`/video/play`,{token:getToken(),id})
    }
    /**
     * 获取tab视频列表
     */
    getTabList(id:any, page: number = 1, limit: number = 20,sort='update_time'): Promise<SuccessFormat> {
        return this.post(`/tab`,{
            page: page,
            limit: limit,
            id,
            sort
        })
    }
    /**
     * 获取栏目视频列表
     */
    getColumnList(id:any, page: number = 1, limit: number = 20): Promise<SuccessFormat> {
        return this.post(`/column`,{
            page: page,
            limit: limit,
            id,
        })
    }
    /**
     * 获取分类视频列表
     */
    getCategoryVideo(category_id:any, page: number = 1, limit: number = 20): Promise<SuccessFormat> {
        return this.post(`/video/by-category`,{
            page: page,
            limit: limit,
            category_id
        })
    }
    /**
     * 搜索视频
     */
    searchVideo(search:any, page: number = 1, limit: number = 20): Promise<SuccessFormat> {
        return this.post(`/search`,{
            page: page,
            limit: limit,
            search
        })
    }
    /**
     * 获取收藏的视频
     */
    getCollectVideo( page: number = 1, limit: number = 20): Promise<SuccessFormat> {
        return this.post(`/my/favorite`,{
            page: page,
            limit: limit,
            token:getToken()
        })
    }
    /**
     * 获取收藏的视频
     */
    getPurchasedtVideo( page: number = 1, limit: number = 20): Promise<SuccessFormat> {
        return this.post(`/my/buy-video`,{
            page: page,
            limit: limit,
            token:getToken()
        })
    }
    /**
     * 获取随机推荐列表
     */
    getRecommendList(): Promise<SuccessFormat> {
        return this.post(`/video/recommend`,{
            limit:12
        })
    }
    /**
     * 视频喜欢
     */
    likeVideo(id:any): Promise<SuccessFormat> {
        return this.post(`/video/favorite`,{token:getToken(),id})
    }
    /**
     * 修改密码
     */
    updatePassword(old_password:string,new_password:string,renew_password:string): Promise<SuccessFormat> {
        return this.post(`/user/password`,{token:getToken(),old_password,new_password,renew_password})
    }
    /**
     * 获取充值物品列表
     */
    getGoodsList(): Promise<SuccessFormat> {
        return this.post(`/charge/item`)
    }
    /**
     * 充值VIP
     */
    rechargeVip(item_id:string,channel_id:string,return_url=''): Promise<SuccessFormat> {
        return this.post(`/charge/buy-vip`,{token:getToken(),item_id,channel_id,return_url})
    }
    /**
     * 购买视频
     */
    buyVideo(video_id:string,channel_id:string,return_url=''): Promise<SuccessFormat> {
        return this.post(`/charge/buy-video`,{token:getToken(),video_id,channel_id,return_url})
    }
    /**
     * 获取验证码
     */
    sendCode(telephone:string): Promise<SuccessFormat> {
        return this.post(`/user/send-code`,{telephone,token:getToken()})
    }
    /**
     * 登录获取验证码
     */
    sendLoginCode(telephone:string): Promise<SuccessFormat> {
        return this.post(`/user/login-send-sms`,{telephone,token:getToken()})
    }
    /**
     * 绑定手机
     */
    bindPhone(telephone:string,code:string): Promise<SuccessFormat> {
        return this.post(`/user/bind-phone`,{token:getToken(),telephone,code})
    }
    /**
     * 用户名登录
     */
    loginByUsername(username:string,password:string): Promise<SuccessFormat> {
        return this.post(`/user/login`,{username,password})
    }
    /**
     * 手机号码登录
     */
    loginByPhoneNumber(telephone:string,code:string): Promise<SuccessFormat> {
        return this.post(`/user/login-by-sms`,{telephone,code})
    }
    /**
     * 获取广告
     */
    getAdvertise(positions:Array<string>): Promise<SuccessFormat> {
        return this.post(`/advertisement`,{positions})
    }
    /**
     * 提交反馈
     */
    sendFeedback(video_id:any,content:string): Promise<SuccessFormat> {
        return this.post(`/feedback`,{video_id,content,token:getToken()})
    }
    /**
     * 获取问题列表
     */
    getQuestions(): Promise<SuccessFormat> {
        return this.post(`/faq`)
    }
    /**
     * 获取我的反馈列表
     */
    getMyQuestions(page: number = 1, limit: number = 20): Promise<SuccessFormat> {
        return this.post(`/my/feedback`,{
            page: page,
            limit: limit,
            token:getToken()
        })
    }
}

export default new AppSDK()
