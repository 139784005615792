import {Link, useNavigate, useParams} from "react-router-dom";
type Props = {
    list:Array<string>
    onClick?: () => void
    className?: string
}
export default function VideoTag({list,className}: Props) {
    const navigate= useNavigate()
    return (
        <>
            {
                list.map((item,index)=>{
                    return (<span onClick={(e)=>{
                        navigate(`/search/${item}`);
                        e.stopPropagation();
                    }} key={index+'tg'} className="video-tag">{`${item}${index==list.length-1?'':'#'}`}</span>)
                })
            }
        </>
    )
}
