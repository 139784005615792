import {Toast} from 'antd-mobile'
import {useNavigate, useParams} from 'react-router-dom'
import Header from "@/components/header";
import React, {useEffect, useRef, useState} from 'react'
import {useSelector} from "react-redux";
import {RootState} from "@/types/store";
import sdk from "@/service/sdk";
import styles from './index.module.scss'
import Footer from "@/components/Footer";
import classnames from "classnames";
import Pagination from "@/components/Pagination";
import { Popover} from 'antd-mobile'
import { v4 as uuidv4 } from 'uuid';
const Message = () => {
    const history = useNavigate()
    const [list, SetList] = useState([{
        content:'uqeouqew',
        contentReply:'uoquewqwe',
        createTime:'713713'
    }])
    const [total, SetTotal] = useState(0)
    const [pageSize, SetPageSize] = useState(10)
    useEffect(() => {
        SetList([])
        SetTotal(0)
        getList(
            1
        )
    }, [])
    const getList = (page: number) => {
        SetList([])
        Toast.show({
            icon: 'loading',
            content: 'loading…',
            duration: 0
        })
        sdk.getMyQuestions().then((res) => {
            if (res.status == 1) {
                SetList(res.data.feedback_list)
                SetTotal(res.data.pagination.total)
            }
        })
    }
    return (<div className={classnames(styles.root, 'arrdv9-0 kaxIlA mobile-page')}>
            <Header></Header>
            <div className="mobile-content">
                <ul className="message-list">
                    <li>
                        <div>
                            反馈内容
                        </div>
                        <div>
                            回复内容
                        </div>
                        <div>
                            时间
                        </div>
                    </li>
                    {
                        list.map((item:any,index:number)=>{
                            return(
                                <li key={uuidv4()}>
                                    <div>
                                        <Popover
                                            content={item.content}
                                            placement='bottom-start'
                                            mode='dark'
                                            trigger='click'
                                            defaultVisible={false}
                                        >
                                            <span className='pop-content'>
                                                {item.content}
                                            </span>
                                        </Popover>
                                    </div>
                                    <div>
                                        <Popover
                                            content={item.reply}
                                            placement='bottom-start'
                                            mode='dark'
                                            trigger='click'
                                            defaultVisible={false}
                                        >
                                            <span className='pop-content'>
                                                 {item.reply}
                                            </span>
                                        </Popover>
                                    </div>
                                    <div>
                                        {item.updated_at}
                                    </div>
                                </li>
                            )
                        })
                    }
            </ul>
                {
                    total ?
                        <Pagination pageSize={pageSize} key={total} total={total} change={getList}></Pagination> : <></>
                }
            </div>
            <Footer></Footer>
        </div>
    )
}

export default Message
