import {Link, useNavigate, useParams} from "react-router-dom";
import React, {useEffect, useState} from 'react'
import Img from "@/components/Img";
import sdk from "@/service/sdk";
type Props = {
    positions:string
    onClick?: () => void
    className?: string
}
export default function Advertisement({positions,className}: Props) {
    const navigate = useNavigate()
    const [list, SetList] = useState<any>([])
    const [count, setCount] = useState(0)
    useEffect(() => {
        let timer:any=null
        let index =0
        sdk.getAdvertise([positions]).then((res) => {
            if (res.status == 1) {
                let arr=res.data.ad_list[positions]
                SetList(res.data.ad_list[positions])
                timer=window.setInterval(() => {
                    if (index < arr.length - 1) {
                        setCount((prevCount) => {
                            return prevCount + 1;
                        });
                        index++
                    } else {
                        setCount(0)
                        index=0
                    }
                }, 5000);
            }
        })
        return () => {
            clearInterval(timer);
        };
    }, [])
    return (
            list&&list.length&&list[count]?<a href={list[count].redirect_url} className={className} key={count+'ad'} target="_blank" style={{display:'block'}}>
                <Img className="sc-gfarJg dJBYtv" src={list[count].image_url}></Img>
            </a>:<></>
    )
}
