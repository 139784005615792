import {AppAction, RootThunkAction} from "@/types/store";
import sdk from '@/service/sdk'
import { removeToken, setToken,getToken } from "@/utils/storage";
import Cookies from "js-cookie";
export const changeMenuState = (state:boolean):AppAction => {
    return {
        type: 'app/setMenuState',
        payload: state,
    }
}
/**
 * 保存全局配置
 * @returns
 */
export const saveConfig = (): RootThunkAction => {
    return async (dispatch) => {
        const res = await sdk.getConfig()
        dispatch({
            type: 'app/saveConfig',
            payload: res.data.config,
        })
        Cookies.set('api',res.data.config.api_url)
    }
}
/**
 * 用户注册
 * @returns
 */
export const registerUser = (): RootThunkAction => {
    return async (dispatch) => {
        const res = await sdk.register()
        dispatch({
            type: 'app/registerUser',
            payload: res.data.token,
        })
        setToken(res.data.token)
        const resInfo = await sdk.getUserInfo()
        dispatch({
            type: 'app/getUserInfo',
            payload: resInfo.data,
        })
    }
}
/**
 * 获取用户信息
 * @returns
 */
export const getUserInfo = (): RootThunkAction => {
    return async (dispatch) => {
        const res = await sdk.getUserInfo()
        dispatch({
            type: 'app/getUserInfo',
            payload: res.data,
        })
    }
}
/**
 * 获取首页信息
 * @returns
 */
export const getIndexData = (): RootThunkAction => {
    return async (dispatch) => {
        const res = await sdk.getIndexData()
        dispatch({
            type: 'app/indexData',
            payload: res.data,
        })
    }
}
/**
 * 获取分类
 * @returns
 */
export const getCategory = (): RootThunkAction => {
    return async (dispatch) => {
        const res = await sdk.getCategoryList()
        dispatch({
            type: 'app/getCategory',
            payload: res.data.category_list,
        })
    }
}
/**
 * 获取头部信息
 * @returns
 */
export const setHeader = (): RootThunkAction => {
    return async (dispatch) => {
        const res = await sdk.getAdvertise(['index_top_roll','menu','index_bottom_float'])
        dispatch({
            type: 'app/setMenu',
            payload: res.data.ad_list.menu||[],
        })
        dispatch({
            type: 'app/setTopText',
            payload: res.data.ad_list.index_top_roll||[],
        })
        dispatch({
            type: 'app/setAdvertisement',
            payload: res.data.ad_list.index_bottom_float||[],
        })
    }
}
/**
 * 获取问题列表
 * @returns
 */
export const setQuestions = (): RootThunkAction => {
    return async (dispatch) => {
        const res = await sdk.getQuestions()
        dispatch({
            type: 'app/setQuestions',
            payload: res.data.faq_list||[],
        })
    }
}
/**
 * 设置历史路由
 * @returns
 */
export const setHistory = (url:string): RootThunkAction => {
    return async (dispatch) => {
        dispatch({
            type: 'app/setHistory',
            payload:url,
        })
    }
}
