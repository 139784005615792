import { AppAction } from '@/types/store'
import produce from 'immer'
import home from "@/store/reducers/home";
interface home {
    column_list:Array<any>,
    banner_list:Array<any>,
    tab_list:Array<any>
}
interface userInfo {
    token:string,
    username:string,
    password:string,
    telephone:string,
    is_vip:boolean,
    vip_due_time:string,
    share_param:string
}
interface config {
    api_url:string,
    backup_site_url:Array<string>,
    service_url:string,
    search_recommend_tags:Array<string>,
    search_recommend_words:Array<string>,
    telegram_username:string,
    invitation_tip:string,
    invitation_enable:boolean,
    site_url:string,
    share_url:string
}
type AppStateType = {
    showMenu: boolean,
    token:string,
    userInfo:userInfo,
    indexData:home,
    category:Array<any>,
    config:config,
    menu:Array<any>,
    topText:Array<any>,
    questions:Array<any>,
    history:string,
    advertisement:Array<any>
}

const AppState: AppStateType = {
    showMenu:false,
    token:'',
    userInfo:{
        token:'',
        username:'',
        password:'',
        telephone:'',
        is_vip:false,
        vip_due_time:'',
        share_param:''
    },
    indexData:{
        column_list:[],
        banner_list:[],
        tab_list:[],
    },
    category:[],
    config:{
        api_url:'',
        backup_site_url:[],
        service_url:'',
        search_recommend_tags:[],
        search_recommend_words:[],
        telegram_username:'',
        invitation_tip:'',
        invitation_enable:false,
        site_url:'',
        share_url:''
    },
    menu:[],
    topText:[],
    questions:[],
    history:'',
    advertisement:[]
}

const app = produce((draft, action: AppAction) => {
    switch (action.type) {
        case 'app/setMenuState':
        {
            draft.showMenu = action.payload
            break
        }
        case 'app/registerUser':
        {
            draft.token = action.payload
            break
        }
        case 'app/indexData':
        {
            draft.indexData = (action.payload as home)
            break
        }
        case 'app/getUserInfo':
        {
            draft.userInfo = action.payload
            break
        }
        case 'app/getCategory':
        {
            draft.category = action.payload
            break
        }
        case 'app/saveConfig':
        {
            draft.config = action.payload
            break
        }
        case 'app/setMenu':
        {
            draft.menu = action.payload
            break
        }
        case 'app/setTopText':
        {
            draft.topText = action.payload
            break
        }
        case 'app/setQuestions':
        {
            draft.questions = action.payload
            break
        }
        case 'app/setHistory':
        {
            draft.history = action.payload
            break
        }
        case 'app/setAdvertisement':
        {
            draft.advertisement = action.payload
            break
        }
        default:
            break
    }
}, AppState)

export default app
