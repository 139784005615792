import React, { useRef, useState,useEffect } from 'react';
import { useInterval } from 'ahooks';
import './index.scss';
interface Props {
  list:Array<any>
}
function Marquee({list}:Props) {
    const marqueeRef = useRef<any>(null);
    let index =0
    useEffect(()=>{
       let inter=setInterval(() => {
            if (!marqueeRef || !marqueeRef.current) return;

            const { height } = marqueeRef.current.getBoundingClientRect();
            const translateYItem = Math.floor(height / (list.length + 1));
            const nextIndex = index + 1;
            let interSetime:any=null
            marqueeRef.current.style.transform = `translateY(-${
                translateYItem * nextIndex
            }px)`;
            marqueeRef.current.style.transition = 'transform 0.8s';

            if (index >= list.length - 1) {
                // 最后一个
                interSetime=setTimeout(() => {
                    index=0;
                    if (marqueeRef.current&&marqueeRef.current.style)
                    {
                        marqueeRef.current.style.transform = 'translateY(0px)';
                        marqueeRef.current.style.transition = 'transform 0s';
                    }
                    clearTimeout(interSetime)
                }, 1000);
            } else {
                index=nextIndex;
            }
        }, 5000);
       /*return clearInterval(inter)*/
    },[])


    return (
        <div className="marquee-container">
            <div className="marquee-wrapper">
                <div className="marquee-content" ref={marqueeRef}>
                    {
                        list.length?list.concat(list[0]).map((item, i) => (
                            <a target="_blank" href={item&&item.redirect_url?item.redirect_url:''} key={`${item}-${i}`} className="sc-11mqo2t-1 iipagV marquee-content-itm">
                                {item.title}
                            </a>
                        )):<></>
                    }
                </div>
            </div>
        </div>
    );
}
export default  Marquee
