type Props = {
    onClick?: () => void
    className?: string,
    size?:string
}
export default function IconShare({size}:Props) {
    return (
    /*<svg style={{width:size,height:size}} className="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg"
         p-id="21581" width="200" height="200">
        <path
            d="M320.544 437.968a16 16 0 0 1 16 16v16a16 16 0 0 1-16 16H240v256h539.04v-256H688a16 16 0 0 1-16-16v-16a16 16 0 0 1 16-16h123.04a16 16 0 0 1 16 16v320a16 16 0 0 1-16 16H208a16 16 0 0 1-16-16v-320a16 16 0 0 1 16-16z m201.92-194.576l135.104 105.44a16 16 0 0 1 2.768 22.448l-9.856 12.608a16 16 0 0 1-22.464 2.752L536.96 315.52l-0.768 274.56a16 16 0 0 1-14.176 15.872l-1.856 0.096-16-0.048a16 16 0 0 1-15.968-16.048l0.768-276.528-94.208 71.008a16 16 0 0 1-21.12-1.6l-1.28-1.536-9.616-12.752a16 16 0 0 1 3.136-22.4l0.032-0.032 137.136-102.88a16 16 0 0 1 19.456 0.16z"
            fill="#5A626A" p-id="21582"></path>
    </svg>*/
    <svg  className="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg"
         p-id="3174" style={{width:size,height:size}}>
        <path  d="M989.584696 0.278261H477.862957a34.270609 34.270609 0 0 0-34.125914 34.137043 34.270609 34.270609 0 0 0 34.125914 34.125913h428.911304L301.04487 668.393739a34.025739 34.025739 0 0 0-0.278261 48.128 34.081391 34.081391 0 0 0 24.342261 10.073044c8.670609 0 17.341217-3.361391 24.052869-9.794783l606.297044-600.409043v429.746086a34.270609 34.270609 0 0 0 34.125913 34.125914 34.270609 34.270609 0 0 0 34.137043-34.125914V34.415304A34.270609 34.270609 0 0 0 989.584696 0.278261z"
            fill="#2E323F" p-id="3175"></path>
        <path
            d="M955.458783 955.458783H68.541217V68.541217a34.270609 34.270609 0 0 0-34.125913-34.125913A34.270609 34.270609 0 0 0 0.278261 68.541217v920.765218a34.270609 34.270609 0 0 0 34.137043 34.137043h920.754087a34.270609 34.270609 0 0 0 34.137044-34.137043 33.792 33.792 0 0 0-33.847652-33.847652z"
            fill="#2E323F" p-id="3176"></path>
    </svg>
    )
}
