import {Modal} from 'antd-mobile'
import styles from './index.module.scss'
import classnames from "classnames";
import Header from '@/components/header'
import React, {useEffect, useState} from "react";
import sdk from "@/service/sdk";
import RechargeModal from "@/components/RechargeModal";
import Footer from "@/components/Footer";

const Recharge = () => {
    const [list, SetList] = useState([])
    const [channelList, SetChannelList] = useState([])
    const [goodsItem, SetGoodsItem] = useState<any>({})
    useEffect(() => {
        getList()
    }, [])
    const getList=()=>{
        sdk.getGoodsList().then((res) => {
            if(res.status==1)
            {
                SetList(res.data.item_list)
                SetChannelList(res.data.channel_list)
                SetGoodsItem(res.data.item_list[0])
            }

        })
    }

    return (
        <div className={classnames(styles.root, 'arrdv9-0 kaxIlA page-recharge')}>
            <Header></Header>
            <div className="sc-1kcttbn-0 bNPNQH">
                {
                    list.map((item:any,index)=>{
                        return (<div onClick={()=>{
                            SetGoodsItem(item)
                        }} key={item.id} className={goodsItem.id==item.id?'sc-1xbpqlx-0 dYLMTo item-active':'sc-1xbpqlx-0 dYLMTo'}>
                            <div className="sc-1xbpqlx-4 bZSGlp"><span className="sc-1xbpqlx-2 lcOZYP">{item.title}</span>
                                <div className="sc-1xbpqlx-1 YxulC"><p>{item.description}</p></div>
                            </div>
                            <div className="sc-1lypyuq-0 bvDcOs">
                                <span className="sc-1lypyuq-3 emiDzr">￥{item.price_yuan}</span>
                                <div className="sc-1lypyuq-5 bzIzuX">
                                    <small className="sc-1lypyuq-4 gOOlIu"></small></div>
                            </div>
                        </div>)
                    })
                }

                <button onClick={() => {
                   RechargeModal(channelList,1,goodsItem)
                }} className="sc-i9x3vi-0 iQMOeb active sc-1jpg5t7-2 sc-1kcttbn-1 hNSosq bBUPnm" type="button">
                   立即支付
                </button>
            </div>
            <Footer></Footer>
        </div>
    )
}

export default Recharge
