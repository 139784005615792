import {Modal} from "antd-mobile";
import React from "react";
import sdk from "@/service/sdk";
import iconWechat from "@/assets/images/icon_wechat.png";
import iconAlipay from "@/assets/images/icon_pay_ali.png";

const RechargeModal = (channelList:Array<any>,type:number,item:any) => {
    const submit=(channelId:string)=>{
        let winOpen:any = window.open("", "_blank");
        if (type==1)
        {
            sdk.rechargeVip(item.id,channelId).then((res)=>{
                if (res.status==1)
                {
                    winOpen.location=res.data.url
                }
                Modal.clear()
            })
        }
        else {
            sdk.buyVideo(item.id,channelId).then((res)=>{
                if (res.status==1)
                {
                    winOpen.location=res.data.url
                }
                Modal.clear()
            })
        }

    }
    Modal.show({
        title: '购买',
        content: (<div className="modal-wrap">
            <div  className="vip-box modal-vip-box">
                <div  className="box-left">{item.title}</div>
                <div  className="box-right">￥{type==1?item.price_yuan:item.pay_amount_yuan}</div>
            </div>
            <div  className="pay-btns">
                {
                    channelList.map((item:any,index)=>{
                        return (item.icon=='zfb'?<a key={item.id} onClick={()=>{
                            submit(item.id)
                        }}><img src={iconAlipay} />支付宝</a>:<a key={item.id} onClick={()=>{
                            submit(item.id)
                        }}><img src={iconWechat} />微信</a>)
                    })
                }
            </div>
        </div>),
        showCloseButton: true,
        className:'recharge-modal'
    })
}

export default RechargeModal
