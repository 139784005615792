import Header from "@/components/header";
import VideoCard from "@/components/VideoCard";
import Player from "@/components/player";
import {VideoInfo} from '@/types/data'
import React, {useEffect, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import sdk from "@/service/sdk";
import {StarOutline, InformationCircleOutline, StarFill} from 'antd-mobile-icons';
import IconShare from "@/components/icons/IconShare";
import { Toast } from 'antd-mobile';
import Footer from "@/components/Footer";
import VideoTag from "@/components/VideoTag";
import {CopyToClipboard} from 'react-copy-to-clipboard';
import Advertisement from "@/components/Advertisement";
import Feedback from "@/components/Feedback";
import {useDispatch} from "react-redux";
import {setHistory} from "@/store/actions/app";
import {v4 as uuidv4} from "uuid";

const Video = () => {
    const [info, SetInfo] = useState<VideoInfo>({} as VideoInfo)
    const [list, SetList] = useState([])
    const [loading, SetLoading] = useState(false)
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const {id} = useParams<{ id: string }>()
    useEffect(() => {
        getInfo()
        Toast.show({
            icon: 'loading',
            content: 'loading…',
            duration:0
        })
        sdk.getRecommendList().then((res) => {
            SetList(res.data.video_list)
        })
        window.scrollTo(0,0)
    }, [id])
    const getInfo = () => {
        Toast.show({
            icon: 'loading',
            content: 'loading…',
            duration:0
        })
        SetLoading(true)
        sdk.getVideoInfo(id).then((res) => {
            if (res.status==1)
            {
                SetInfo(res.data.video)
                SetLoading(false)
                if (res.data.video.need_pay)
                {

                }
            }

        })
    }
    const likeVideo = () => {
        Toast.show({
            icon: 'loading',
            content: 'loading…',
            duration:0
        })
        sdk.likeVideo(id).then((res) => {
            if (res.status == 1) {
                Toast.show({
                    icon: 'success',
                    content: '保存成功',
                })
                getInfo()
            }
        })
    }
    return (
        <div className="arrdv9-0 kaxIlA video-page">
            <Header></Header>
            <Player loading={loading} info={info} key={info.id + 'pl'}></Player>
            <div className="video-info">
                <h2 className="Heading__H2-sc-1lip0t2-1 bbatoq Heading-sc-1icgkho-0 iZvjSX">[{info.pay_mode == 1 ? '付费' : '收费'}]
                    [{info.category_name}]
                    {info.title}</h2>
                <div className="video-utils">
                    <div className="video-utils-item">
                        <span className="Text-sc-oexese-0 hDzty">发布于{info.created_date}</span>
                        <CopyToClipboard text={window.location.href} onCopy={() => Toast.show({
                            icon: 'success',
                            content: '复制成功!',
                        })}>
                            <span id="copybtn" className="Text-sc-oexese-0 hDzty">
                            <IconShare size={'12px'}></IconShare>分享
                        </span>
                        </CopyToClipboard>
                    </div>
                    <div className="video-utils-item">
                        <span className="Text-sc-oexese-0 hGwqzl">{info.play_count} 观看 </span>
                        <span className="icons Text-sc-oexese-0 hGwqzl" onClick={() => {
                            likeVideo()
                        }}> 收藏: {info.is_favorite ? <StarFill color='#ff878b' fontSize={16}/> :
                            <StarOutline fontSize={16}/>}</span>
                        <span onClick={()=>{
                            Feedback(id)
                        }} className="icons Text-sc-oexese-0 hGwqzl"> 问题反馈: <InformationCircleOutline className='feedback-icon' fontSize={16}/></span>
                    </div>
                    <div className="Line-sc-1tgn5e2-0 ifiHPs"></div>
                    <div className="Structure-sc-122ascm-0 kUYGou">
                        <div className="Structure-sc-122ascm-0 Categories__Wrapper-sc-g4926r-0 dWewhh lnDoTz">
                            <span className="Text-sc-oexese-0 hDzty">类别:</span>
                            <span className="Text-sc-oexese-0 hDzty">{info.category_name}</span>
                        </div>
                        <div className="Structure-sc-122ascm-0 Categories__Wrapper-sc-g4926r-0 dWewhh lnDoTz">
                            <span className="Text-sc-oexese-0 hDzty">标签:</span>
                            <span className="Text-sc-oexese-0 hDzty">
                                {info.tags?<VideoTag list={info.tags}></VideoTag>:<></>}
                                {/*<VideoTag list={info.tags}></VideoTag>*/}
                            </span>
                        </div>
                    </div>
                </div>
            </div>
            <div className="sc-eCjkpP relate-list">
                <div className="sc-clhSjD yLshi">
                    <Advertisement positions={'play_middle'}></Advertisement>
                    <div className="Structure-sc-122ascm-0 gCNTYn">
                        <h2 className="Heading__H2-sc-1lip0t2-1 bbatoq Heading-sc-1icgkho-0 lLHvW">相关视频</h2>
                    </div>
                    {
                        list.map((item: any, index) => {
                            return (
                                <VideoCard  info={item} key={item.id}></VideoCard>
                            )
                        })
                    }

                </div>
            </div>
            <Footer></Footer>
        </div>
    )
}

export default Video
