import {Link, useNavigate, useParams,useNavigationType} from 'react-router-dom'

import Icon from '@/components/icon'
import styles from './index.module.scss'
import React, {useEffect, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {RootState} from '@/types/store'
import Header from "@/components/header";
import Footer from "@/components/Footer";
import VideoCard from "@/components/VideoCard";
import {VideoInfo} from "@/types/data";
import sdk from "@/service/sdk";
import Pagination from "@/components/Pagination";
import {Empty, Toast} from 'antd-mobile';
import { v4 as uuidv4 } from 'uuid';
import {useActivate} from "react-activation";

const Search = () => {
    const [list, SetList] = useState([])
    const [total, SetTotal] = useState(0)
    const [pageSize, SetPageSize] = useState(10)
    const [isLoading, SetIsLoading] = useState(false)
    const navigate = useNavigate()
    const navigationType =useNavigationType()
    // 获取地址里的id值
    const {key} = useParams<{ key: string }>()
    const [keyword, SetKeyword] = useState('')
    useEffect(() => {
        SetList([])
        SetTotal(0)
        getList(
            1
        )
        SetKeyword(key as string)
    }, [key])
    useActivate(()=>{
        if (navigationType!=='POP')
        {
            SetTotal(0)
            SetList([])
            getList(
                1
            )
        }
        else {
            window.scrollTo(0,0)
        }
    })
    const getList = (page: number) => {
        SetList([])
        Toast.show({
            icon: 'loading',
            content: 'loading…',
            duration: 0
        })
        SetIsLoading(true)
        sdk.searchVideo(key, page, 20).then((res) => {
            if (res.status == 1) {
                SetList(res.data.video_list)
                SetTotal(res.data.pagination.total)
            }
            SetIsLoading(false)
        })
    }
    const handleSearch=(event:any)=>{
        if (event.keyCode === 13) {
            navigate(`/search/${keyword}`)
        }
    }
    return (
        <div className="arrdv9-0 kaxIlA">
            <Header></Header>
            <div className="sc-eCjkpP iDHATf mt-20">
                <h1 className="Heading__H1-sc-1lip0t2-0 jqKNYI Heading-sc-1icgkho-0 Header__Heading-sc-19h6x14-0 jtybvW"> 搜索结果</h1>
                <div className="Structure-sc-122ascm-0 bLAQEM">
                    <div className="Structure-sc-122ascm-0 AgPoo">
                        <div onClick={()=>{
                            navigate(`/search/${keyword}`)
                        }} className="Structure-sc-122ascm-0 jwdmKA">
                            <i color="#222222"  className="Icon__Wrapper-sc-xsbbqc-0 dgPyry">
                            <svg viewBox="0 0 512 512" width="24px" height="24px" xmlns="http://www.w3.org/2000/svg"
                                 className="SVG-sc-kdjddv-0 kBXcXC">
                                <path
                                    d="M508.5 481.6l-129-129c-2.3-2.3-5.3-3.5-8.5-3.5h-10.3C395 312 416 262.5 416 208 416 93.1 322.9 0 208 0S0 93.1 0 208s93.1 208 208 208c54.5 0 104-21 141.1-55.2V371c0 3.2 1.3 6.2 3.5 8.5l129 129c4.7 4.7 12.3 4.7 17 0l9.9-9.9c4.7-4.7 4.7-12.3 0-17zM208 384c-97.3 0-176-78.7-176-176S110.7 32 208 32s176 78.7 176 176-78.7 176-176 176z"></path>
                            </svg>
                            </i>
                        </div>
                        <input value={keyword}
                               onKeyDown={(e)=>{handleSearch(e)}}
                               onChange={(e) => SetKeyword(e.target.value)} type="text" name="q" autoComplete="off" placeholder="Search"  className="Input-sc-179fhke-0 Filters__Input-sc-93cjj7-0 irDggH kaYYcQ"/>
                    </div>
                </div>
                <div className="sc-clhSjD yLshi">
                    {
                        list.length?list.map((item: any, index) => {
                            return (
                                <VideoCard  info={item} key={uuidv4()}></VideoCard>
                            )
                        }):(!isLoading?<Empty description='暂无数据' />:<></>)
                    }

                </div>
                {
                    total ?
                        <Pagination pageSize={pageSize} key={total} total={total} change={getList}></Pagination> : <></>
                }
            </div>
            <Footer></Footer>
        </div>
    )
}

export default React.memo(Search)
