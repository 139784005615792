import {useNavigate, useParams,Link} from 'react-router-dom'
import Header from "@/components/header";
import React, {useEffect, useRef, useState} from 'react'
import {VideoInfo} from "@/types/data";
import {useSelector} from "react-redux";
import {RootState} from "@/types/store";
import sdk from "@/service/sdk";
import Footer from "@/components/Footer";
import LoginModal from "@/components/LoginModal";
import {getIndexData} from "@/store/actions/app";
import {Modal, Toast} from "antd-mobile";
import {setToken} from "@/utils/storage";
// import { highlight } from '@/utils'
// import { useDispatch } from 'react-redux'
const Account = () => {
    const navigate = useNavigate()
    const [showCurrent, SetShowCurrent] = useState(false)
    const [showNew, SetShowNew] = useState(false)
    const [showConfirm, SetShowConfirm] = useState(false)
    const [password, SetPassword] = useState('')
    const [newPassword, SetNewPassword] = useState('')
    const [confirmPassword, SetConfirmPassword] = useState('')
    const userInfo = useSelector(
        (state: RootState) => {
            return state.app.userInfo
        }
    )
    const submit=()=>{
        sdk.updatePassword(password,newPassword,confirmPassword).then((res)=>{
            if(res.status==1)
            {
                Toast.show({
                    icon: 'success',
                    content: '修改成功!',
                })
                Modal.clear()
                setToken(res.data.token)
                window.location.reload()
            }
        })
    }
    return (<div className="arrdv9-0 kaxIlA account-page">
            <Header></Header>
            <div className="account-content">
                <div className="Structure-sc-122ascm-0 dWewhh">
                    <div className="Structure-sc-122ascm-0 dxCrhe">
                        <a onClick={()=>{
                            LoginModal()
                        }} className="Navigation__Logout-sc-1jvcpw8-1 jqNckD">
                        <span className="Text-sc-oexese-0 dfBjvj">
                            切换账户
                        </span>
                        </a>
                    </div>
                    <div className="Structure-sc-122ascm-0 kUYGou">
                        <div className="Line-sc-1tgn5e2-0 ifiHPs"></div>
                    </div>
                </div>
                <div className="Structure-sc-122ascm-0 flayst">
                    <div className="Structure-sc-122ascm-0 dWewhh">
                        <div className="Structure-sc-122ascm-0 kUYGou">
                            <h2 className="Heading__H2-sc-1lip0t2-1 bbatoq Heading-sc-1icgkho-0 cVvOCk">个人资料</h2>
                        </div>
                        <div className="Structure-sc-122ascm-0 kUYGou">
                            <span className="Text-sc-oexese-0 bgPWRq">用户名：</span>
                            <span className="Text-sc-oexese-0 dnXEew">{userInfo.username}</span>
                        </div>
                        {
                            userInfo.telephone?<div className="Structure-sc-122ascm-0 kUYGou">
                                <span className="Text-sc-oexese-0 bgPWRq">手机号：</span>
                                <span className="Text-sc-oexese-0 dnXEew">{userInfo.telephone}</span>
                            </div>:<></>
                        }
                        <div className="Structure-sc-122ascm-0 dWewhh">
                            <span className="Text-sc-oexese-0 bgPWRq">密码：</span>
                            <span className="Text-sc-oexese-0 dnXEew">{userInfo.password}</span>
                        </div>
                        {
                            userInfo.is_vip?<div className="Structure-sc-122ascm-0 dWewhh">
                                <span className="Text-sc-oexese-0 bgPWRq">VIP时长：</span>
                                <span className="Text-sc-oexese-0 dnXEew">{userInfo.vip_due_time}</span>
                            </div>:<></>
                        }
                        <div className="bind-btn-wrap">
                            <Link to="/phone" className="Navigation__Logout-sc-1jvcpw8-1 jqNckD">
                                <span className="Text-sc-oexese-0 dfBjvj">绑定手机</span>
                            </Link>
                        </div>
                    </div>
                </div>
                <div className="Structure-sc-122ascm-0 flayst">
                    <div className="Structure-sc-122ascm-0 dWewhh">
                        <div className="Structure-sc-122ascm-0 kUYGou">
                            <div className="Structure-sc-122ascm-0 gCNTYn">
                                <h2 className="Heading__H2-sc-1lip0t2-1 bbatoq Heading-sc-1icgkho-0 cVvOCk">
                                    更改密码</h2></div>
                            <p color="grey500"
                               className="Paragraph-sc-1ibapb4-0 Paragraph-sc-1rqhdxo-0 gLnZup bhqtR">关于良好密码卫生的一些提示：1.
                                不要重复使用相同的密码2. 使用<a><span className="Text-sc-oexese-0 dnXEew">密码短语</span></a>，它更长且更容易记住3.
                                或者使用<a><span className="Text-sc-oexese-0 dnXEew">密码管理器</span></a>来生成和存储您的密码<a><span
                                    className="Text-sc-oexese-0 dnXEew"></span></a><a><span
                                    className="Text-sc-oexese-0 dnXEew"></span></a></p><p
                            className="Paragraph-sc-1ibapb4-0 Paragraph-sc-1rqhdxo-0 gLnZup bhqtR">
                            更新密码后，您将被注销
                        </p></div>
                        <div className="Structure-sc-122ascm-0 dWewhh">
                            <div>
                                <div className="Structure-sc-122ascm-0 fPVyDJ">
                                    <div className="Structure-sc-122ascm-0 kUYGou">
                                        <div className="Input__Wrapper-sc-afb25n-0 BMDUb">
                                            <div className="Input__InputWrapper-sc-afb25n-3 jSzqLK">
                                                <input
                                                type={showCurrent?'text':'password'}
                                                value={password}
                                                onChange={(e) => SetPassword(e.target.value)}
                                                id="current-password"
                                                name="current-password"
                                                autoComplete="current-password"
                                                className="Input-sc-179fhke-0 Input__InputElement-sc-afb25n-6 irDggH NXyZC"/>
                                                <div className="Input__After-sc-afb25n-5 eGBVkV">
                                                    <button onClick={()=>{
                                                        SetShowCurrent(!showCurrent)
                                                    }} type="button"
                                                            className="Button-sc-uvpcy4-0 Password__Button-sc-1ef9qcw-0 kwplws eDWyDf">
                                                        {
                                                            showCurrent?<i  color="currentColor"
                                                                              className="Icon__Wrapper-sc-xsbbqc-0 bSqwIZ">
                                                                <svg viewBox="0 0 640 512" width="24.864px"
                                                                     height="24.864px" xmlns="http://www.w3.org/2000/svg"
                                                                     className="SVG-sc-kdjddv-0 kBXcXC">
                                                                    <path
                                                                        d="M634 471L36 3.51A16 16 0 0 0 13.51 6l-10 12.49A16 16 0 0 0 6 41l598 467.49a16 16 0 0 0 22.49-2.49l10-12.49A16 16 0 0 0 634 471zM296.79 146.47l134.79 105.38C429.36 191.91 380.48 144 320 144a112.26 112.26 0 0 0-23.21 2.47zm46.42 219.07L208.42 260.16C210.65 320.09 259.53 368 320 368a113 113 0 0 0 23.21-2.46zM320 112c98.65 0 189.09 55 237.93 144a285.53 285.53 0 0 1-44 60.2l37.74 29.5a333.7 333.7 0 0 0 52.9-75.11 32.35 32.35 0 0 0 0-29.19C550.29 135.59 442.93 64 320 64c-36.7 0-71.71 7-104.63 18.81l46.41 36.29c18.94-4.3 38.34-7.1 58.22-7.1zm0 288c-98.65 0-189.08-55-237.93-144a285.47 285.47 0 0 1 44.05-60.19l-37.74-29.5a333.6 333.6 0 0 0-52.89 75.1 32.35 32.35 0 0 0 0 29.19C89.72 376.41 197.08 448 320 448c36.7 0 71.71-7.05 104.63-18.81l-46.41-36.28C359.28 397.2 339.89 400 320 400z"></path>
                                                                </svg>
                                                            </i>:<i color="currentColor"
                                                                    className="Icon__Wrapper-sc-xsbbqc-0 eZDHil">
                                                                <svg viewBox="0 0 576 512" width="22.4px" height="22.4px"
                                                                     xmlns="http://www.w3.org/2000/svg"
                                                                     className="SVG-sc-kdjddv-0 kBXcXC">
                                                                    <path
                                                                        d="M288 144a110.94 110.94 0 0 0-31.24 5 55.4 55.4 0 0 1 7.24 27 56 56 0 0 1-56 56 55.4 55.4 0 0 1-27-7.24A111.71 111.71 0 1 0 288 144zm284.52 97.4C518.29 135.59 410.93 64 288 64S57.68 135.64 3.48 241.41a32.35 32.35 0 0 0 0 29.19C57.71 376.41 165.07 448 288 448s230.32-71.64 284.52-177.41a32.35 32.35 0 0 0 0-29.19zM288 400c-98.65 0-189.09-55-237.93-144C98.91 167 189.34 112 288 112s189.09 55 237.93 144C477.1 345 386.66 400 288 400z"></path>
                                                                </svg>
                                                            </i>
                                                        }

                                                    </button>
                                                </div>
                                            </div>
                                            <label htmlFor="current-password"
                                                   className="Input__Label-sc-afb25n-1 jJQUgq">当前密码</label></div>
                                    </div>
                                    <div className="Structure-sc-122ascm-0 feuTYo">
                                        <div className="Input__Wrapper-sc-afb25n-0 BMDUb">
                                            <div className="Input__InputWrapper-sc-afb25n-3 jSzqLK">
                                                <input
                                                type={showNew?'text':'password'}
                                                id="new-password"
                                                name="new-password"
                                                value={newPassword}
                                                onChange={(e) => SetNewPassword(e.target.value)}
                                                autoComplete="new-password"
                                                className="Input-sc-179fhke-0 Input__InputElement-sc-afb25n-6 irDggH NXyZC"/>
                                                <div className="Input__After-sc-afb25n-5 eGBVkV">
                                                    <button onClick={()=>{
                                                        SetShowNew(!showNew)
                                                    }} type="button"
                                                            className="Button-sc-uvpcy4-0 Password__Button-sc-1ef9qcw-0 kwplws eDWyDf">
                                                        {
                                                            showNew?<i  color="currentColor"
                                                                            className="Icon__Wrapper-sc-xsbbqc-0 bSqwIZ">
                                                                <svg viewBox="0 0 640 512" width="24.864px"
                                                                     height="24.864px" xmlns="http://www.w3.org/2000/svg"
                                                                     className="SVG-sc-kdjddv-0 kBXcXC">
                                                                    <path
                                                                        d="M634 471L36 3.51A16 16 0 0 0 13.51 6l-10 12.49A16 16 0 0 0 6 41l598 467.49a16 16 0 0 0 22.49-2.49l10-12.49A16 16 0 0 0 634 471zM296.79 146.47l134.79 105.38C429.36 191.91 380.48 144 320 144a112.26 112.26 0 0 0-23.21 2.47zm46.42 219.07L208.42 260.16C210.65 320.09 259.53 368 320 368a113 113 0 0 0 23.21-2.46zM320 112c98.65 0 189.09 55 237.93 144a285.53 285.53 0 0 1-44 60.2l37.74 29.5a333.7 333.7 0 0 0 52.9-75.11 32.35 32.35 0 0 0 0-29.19C550.29 135.59 442.93 64 320 64c-36.7 0-71.71 7-104.63 18.81l46.41 36.29c18.94-4.3 38.34-7.1 58.22-7.1zm0 288c-98.65 0-189.08-55-237.93-144a285.47 285.47 0 0 1 44.05-60.19l-37.74-29.5a333.6 333.6 0 0 0-52.89 75.1 32.35 32.35 0 0 0 0 29.19C89.72 376.41 197.08 448 320 448c36.7 0 71.71-7.05 104.63-18.81l-46.41-36.28C359.28 397.2 339.89 400 320 400z"></path>
                                                                </svg>
                                                            </i>:<i color="currentColor"
                                                                    className="Icon__Wrapper-sc-xsbbqc-0 eZDHil">
                                                                <svg viewBox="0 0 576 512" width="22.4px" height="22.4px"
                                                                     xmlns="http://www.w3.org/2000/svg"
                                                                     className="SVG-sc-kdjddv-0 kBXcXC">
                                                                    <path
                                                                        d="M288 144a110.94 110.94 0 0 0-31.24 5 55.4 55.4 0 0 1 7.24 27 56 56 0 0 1-56 56 55.4 55.4 0 0 1-27-7.24A111.71 111.71 0 1 0 288 144zm284.52 97.4C518.29 135.59 410.93 64 288 64S57.68 135.64 3.48 241.41a32.35 32.35 0 0 0 0 29.19C57.71 376.41 165.07 448 288 448s230.32-71.64 284.52-177.41a32.35 32.35 0 0 0 0-29.19zM288 400c-98.65 0-189.09-55-237.93-144C98.91 167 189.34 112 288 112s189.09 55 237.93 144C477.1 345 386.66 400 288 400z"></path>
                                                                </svg>
                                                            </i>
                                                        }
                                                    </button>
                                                </div>
                                            </div>
                                            <label htmlFor="new-password"
                                                   className="Input__Label-sc-afb25n-1 jJQUgq">新密码</label><span
                                            className="Input__Message-sc-afb25n-2 gxKEqq">至少 8 个字符</span></div>
                                    </div>
                                    <div className="Structure-sc-122ascm-0 feuTYo">
                                        <div className="Input__Wrapper-sc-afb25n-0 BMDUb">
                                            <div className="Input__InputWrapper-sc-afb25n-3 jSzqLK">
                                                <input type={showConfirm?'text':'password'} id="confirm-new-password"
                                                       name="confirm-new-password"
                                                       autoComplete="on"
                                                       value={confirmPassword}
                                                       onChange={(e) => SetConfirmPassword(e.target.value)}
                                                       className="Input-sc-179fhke-0 Input__InputElement-sc-afb25n-6 irDggH NXyZC"/>
                                                <div className="Input__After-sc-afb25n-5 eGBVkV">
                                                    <button onClick={()=>{
                                                        SetShowConfirm(!showConfirm)
                                                    }} type="button"
                                                            className="Button-sc-uvpcy4-0 Password__Button-sc-1ef9qcw-0 kwplws eDWyDf">
                                                        {
                                                            showConfirm?<i  color="currentColor"
                                                                            className="Icon__Wrapper-sc-xsbbqc-0 bSqwIZ">
                                                                <svg viewBox="0 0 640 512" width="24.864px"
                                                                     height="24.864px" xmlns="http://www.w3.org/2000/svg"
                                                                     className="SVG-sc-kdjddv-0 kBXcXC">
                                                                    <path
                                                                        d="M634 471L36 3.51A16 16 0 0 0 13.51 6l-10 12.49A16 16 0 0 0 6 41l598 467.49a16 16 0 0 0 22.49-2.49l10-12.49A16 16 0 0 0 634 471zM296.79 146.47l134.79 105.38C429.36 191.91 380.48 144 320 144a112.26 112.26 0 0 0-23.21 2.47zm46.42 219.07L208.42 260.16C210.65 320.09 259.53 368 320 368a113 113 0 0 0 23.21-2.46zM320 112c98.65 0 189.09 55 237.93 144a285.53 285.53 0 0 1-44 60.2l37.74 29.5a333.7 333.7 0 0 0 52.9-75.11 32.35 32.35 0 0 0 0-29.19C550.29 135.59 442.93 64 320 64c-36.7 0-71.71 7-104.63 18.81l46.41 36.29c18.94-4.3 38.34-7.1 58.22-7.1zm0 288c-98.65 0-189.08-55-237.93-144a285.47 285.47 0 0 1 44.05-60.19l-37.74-29.5a333.6 333.6 0 0 0-52.89 75.1 32.35 32.35 0 0 0 0 29.19C89.72 376.41 197.08 448 320 448c36.7 0 71.71-7.05 104.63-18.81l-46.41-36.28C359.28 397.2 339.89 400 320 400z"></path>
                                                                </svg>
                                                            </i>:<i color="currentColor"
                                                                    className="Icon__Wrapper-sc-xsbbqc-0 eZDHil">
                                                                <svg viewBox="0 0 576 512" width="22.4px" height="22.4px"
                                                                     xmlns="http://www.w3.org/2000/svg"
                                                                     className="SVG-sc-kdjddv-0 kBXcXC">
                                                                    <path
                                                                        d="M288 144a110.94 110.94 0 0 0-31.24 5 55.4 55.4 0 0 1 7.24 27 56 56 0 0 1-56 56 55.4 55.4 0 0 1-27-7.24A111.71 111.71 0 1 0 288 144zm284.52 97.4C518.29 135.59 410.93 64 288 64S57.68 135.64 3.48 241.41a32.35 32.35 0 0 0 0 29.19C57.71 376.41 165.07 448 288 448s230.32-71.64 284.52-177.41a32.35 32.35 0 0 0 0-29.19zM288 400c-98.65 0-189.09-55-237.93-144C98.91 167 189.34 112 288 112s189.09 55 237.93 144C477.1 345 386.66 400 288 400z"></path>
                                                                </svg>
                                                            </i>
                                                        }
                                                    </button>
                                                </div>
                                            </div>
                                            <label htmlFor="confirm-new-password"
                                                   className="Input__Label-sc-afb25n-1 jJQUgq">确认新密码</label><span
                                            className="Input__Message-sc-afb25n-2 gxKEqq">必须与新密码相同</span></div>
                                    </div>
                                </div>
                                <div className="Structure-sc-122ascm-0 iVbMDf">
                                    <div className="Structure-sc-122ascm-0 dKmixv">
                                        <button onClick={()=>{
                                            submit()
                                        }} type="submit"
                                                className="Button-sc-uvpcy4-0 Button-sc-1nfhkv6-0 kwplws dxPGQA">修改
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer></Footer>
        </div>
    )
}

export default Account
