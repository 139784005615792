import React, {useEffect, useState, useRef} from "react";
import sdk from "@/service/sdk";
import {Toast} from 'antd-mobile'
import './index.css'
import {useSelector} from "react-redux";
import {RootState} from "@/types/store";
import {Link, useNavigate, useParams} from "react-router-dom";
import {Collapse, Popup} from 'antd-mobile';
import logo from '@/assets/images/logo.png';
import Img from "@/components/Img";
import closeIcon from '@/assets/images/close.png';

type Props = {
    onClick?: Function,
}
const AdvList = () => {
    const advertisement = useSelector(
        (state: RootState) => state.app.advertisement
    )
    const [show, SetShow] = useState(true)
    const [count, setCount] = useState(0)
    let index =0
    useEffect(() => {
        const timer = window.setInterval(() => {
            if (index < advertisement.length - 1) {
                index++
                setCount((prevCount) => {
                    return prevCount + 1;
                });
            } else {
                setCount(0)
                index=0
            }
        }, 5000);
        return () => {
            clearInterval(timer);
        };
    }, [advertisement]);
    return (show?<>
        {
            advertisement[count]?<a target="_blank" href={advertisement[count].redirect_url} className='adv-wrap'>
                {
                    <Img key={count+'bt-ad'} src={advertisement[count].image_url}></Img>
                }
            </a>:<></>
        }
        <div className="close-button" onClick={()=>{
            SetShow(false)
        }}>
            <img src={closeIcon}/>
        </div>
    </>:<></>)
}
const Footer = ({onClick}: Props) => {
    const [config] = useSelector(
        (state: RootState) => {
            return [state.app.config]
        }
    )
    const navigate = useNavigate()
    const [questions] = useSelector(
        (state: RootState) => {
            return [state.app.questions]
        }
    )
    const [visible, setVisible] = useState(false)
    const [content, setContent] = useState('')
    useEffect(() => {

    }, [config])
    const toLink=(path:string)=>{
        navigate(path)
        window.scrollTo(0,0)
    }
    return (<>
            <div className="sc-932b8t-0 kulCVZ">
                <div className="container">
                    <div className="footer-logo">
                        <img className="logo" src={logo}/>
                    </div>
                    <div className="footer-word">
                        <div className="tag-word">
                            <div>
                                <span
                                    className="Text-sc-oexese-0 Footer__SectionTitle-sc-quxnkc-2 bdpLBo kTSifz">热门标签</span>
                                <div>
                                    {
                                        config.search_recommend_tags.map((item, index) => {
                                            return <a onClick={()=>{
                                                toLink(`/search/${item}`)
                                            }} key={index + 'tag'}
                                                         className="Footer__Link-sc-quxnkc-0 iKwwi">{item}</a>
                                        })
                                    }
                                </div>
                            </div>
                        </div>
                        <div className="search-word">
                            <div>
                                <span
                                    className="Text-sc-oexese-0 Footer__SectionTitle-sc-quxnkc-2 bdpLBo kTSifz">热门搜索词</span>
                                <div>
                                    {
                                        config.search_recommend_words.map((item, index) => {
                                            return <a onClick={()=>{
                                                toLink(`/search/${item}`)
                                            }} key={index + 'search'}
                                                         className="Footer__Link-sc-quxnkc-0 iKwwi">{item}</a>
                                        })
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="footer-utils">
                        <Collapse defaultActiveKey={['1']}>
                            <Collapse.Panel key='1' title='常见问题'>
                                {
                                    questions.map((item, index) => {
                                        return (<div onClick={() => {
                                            setVisible(true)
                                            setContent(item.content)
                                        }} key={index + 'text'} className="question-item">
                                            {item.title}
                                        </div>)
                                    })
                                }
                            </Collapse.Panel>
                            <Collapse.Panel key='2' title='联系我们'>
                                <div className="contact-item">
                                    telegram号：<a
                                    href={`https://t.me/${config.telegram_username}`}>点击添加{config.telegram_username}</a>
                                </div>
                                <div className="contact-item">
                                    <a target="_blank" href={config.service_url}>联系客服</a>
                                </div>
                            </Collapse.Panel>
                        </Collapse>
                    </div>
                    <div className='footer-adv'>
                        <div className="about-us">
                            <p>未满十八岁用户请自行离开</p>
                            <p>充值前请绑定手机号并保存账户信息防止用户丢失</p>
                            <p>本站内容皆为虚拟产品,一经充值概不退款</p>
                            <p>@2024SMHUB版权所有</p>
                        </div>
                        <AdvList></AdvList>
                    </div>
                </div>
            </div>
            <Popup
                className="footer-pop"
                visible={visible}
                onMaskClick={() => {
                    setVisible(false)
                }}

                bodyStyle={{height: '40vh'}}
            >
                <div className="footer-pop-content">
                    {content}
                </div>
            </Popup>
        </>
    )
}
export default Footer;
