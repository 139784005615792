import React from "react";

type Props = {
    onClick?: () => void
    className?: string,
    size?: string
}
export default function IconBack({size}: Props) {
    return (
        <svg style={{width: size, height: size}} className="icon" viewBox="0 0 1024 1024" version="1.1"
             xmlns="http://www.w3.org/2000/svg" p-id="2323" width="200" height="200">
            <path
                d="M943.2 899.9s-8.8 22.6-20.5 0c0 0-130.7-394.4-449.3-295.1v122.4s-5.1 72-67.9 25.1L92.4 481.9s-66.4-36.1 4-85.6l316.6-272s47.6-33.9 59.1 21.7l0.4 132.1c-0.1 0.1 593.7 28.6 470.7 621.8z"
                fill="#515151" p-id="2324"></path>
        </svg>
    )
}
