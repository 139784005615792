import {Link, useNavigate, useParams,useNavigationType} from 'react-router-dom'

import Icon from '@/components/icon'
import styles from './index.module.scss'
import React, {useEffect, useState,useRef} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '@/types/store'
import Header from "@/components/header";
import VideoCard from "@/components/VideoCard";
import {VideoInfo} from "@/types/data";
import sdk from "@/service/sdk";
import Pagination from "@/components/Pagination";
import { Toast,Empty} from 'antd-mobile';
import Footer from "@/components/Footer";
import {getIndexData, setHistory} from "@/store/actions/app";
import { v4 as uuidv4 } from 'uuid';
import { useActivate, useUnactivate, withActivation } from 'react-activation'
import {useLocation} from "react-router";
type Props = {
    sort: number,
    change:(type:number) => void
}
const Sort=({sort,change}:Props)=>{
    useEffect(() => {

    }, [sort])
    return (<div className="sort-wrap">
        <a onClick={()=>{
            change(0)
        }} className={sort==0?"active":""}>最近更新</a>
        <a onClick={()=>{
            change(1)
        }} className={sort==1?"active":""}>最长</a>
        <a onClick={()=>{
            change(2)
        }} className={sort==2?"active":""}>最受欢迎</a>
    </div>)
}
const Tab = () => {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const location = useLocation()
    const navigationType =useNavigationType()
    const [list, SetList] = useState([])
    const [total, SetTotal] = useState(0)
    const [isLoading, SetIsLoading] = useState(false)
    const [pageSize, SetPageSize] = useState(10)
    const sort = useRef(0)
    const sortList:Array<string>=['update_time','play_time','play_count']
    // 获取地址里的id值
    const { id,cid,name,cname} = useParams<{ id: string,cid:string,name:string,cname:string}>()
    useEffect(() => {
        sort.current=0
        SetList([])
        getList(
            1
        )
        /*dispatch(setHistory(history.location.pathname))*/
    }, [id,cid])
    useActivate(()=>{
        if (navigationType!=='POP')
        {
            sort.current=0
            SetList([])
            getList(
                1
            )

        }
        else {
            window.scrollTo(0,0)
        }
    })
    const getList=(page:number,type=0)=>{
        SetList([])
        Toast.show({
            icon: 'loading',
            content: 'loading…',
            duration:0
        })
        SetIsLoading(true)
        if (cid)
        {
            sdk.getCategoryVideo(cid,page,20).then((res) => {
                if (res.status==1)
                {
                    SetList(res.data.video_list)
                    SetTotal(res.data.pagination.total)
                }
                SetIsLoading(false)
            })
        }
        else {
            sdk.getTabList(id,page,20,sortList[sort.current]).then((res) => {
                if (res.status==1)
                {
                    SetList(res.data.video_list)
                    SetTotal(res.data.pagination.total)
                }
                SetIsLoading(false)

            })
        }

    }
    const handleSort=(type:number)=>{
        sort.current=type
        getList(
            1
        )
    }
    return (
        <div className="arrdv9-0 kaxIlA">
            <Header></Header>
            <div className={location.pathname.indexOf('tab')!=-1&&(id!='free')||location.pathname.indexOf('category')!=-1?"sc-eCjkpP iDHATf mt-20 cat-top":"sc-eCjkpP iDHATf mt-20"}>
                {
                    cid?<h3 className="cate-title">当前分类:{name}&gt;{cname}</h3>:<></>
                }
                {
                    cid?<></>:<Sort key={sort.current+'sort'} sort={sort.current} change={(type)=>{
                        handleSort(type)
                        }
                    }></Sort>
                }
                <div className="sc-clhSjD yLshi">
                    {
                        list.length&&!isLoading?list.map((item:any,index) => {
                            return (
                                <VideoCard  info={item} key={uuidv4()}></VideoCard>
                            )
                        }):(!isLoading?<Empty description='暂无数据' />:<></>)
                    }
                </div>
                {
                    total?<Pagination pageSize={pageSize} key={total+''+sort.current+''+id+''+cid} total={total}  change={getList}></Pagination>:<></>
                }
            </div>
            <Footer></Footer>
        </div>
    )
}

export default React.memo(Tab)
