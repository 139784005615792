import {Toast} from 'antd-mobile'
import {useNavigate, useParams} from 'react-router-dom'
import Header from "@/components/header";
import React, {useEffect, useRef, useState} from 'react'
import {useSelector} from "react-redux";
import {RootState} from "@/types/store";
import sdk from "@/service/sdk"
import Footer from "@/components/Footer";

const Backurl = () => {
    const history = useNavigate()
    const [list] = useSelector(
        (state: RootState) => [state.app.config.backup_site_url]
    )
    const [speedList, SetSpeedList] = useState<any[]>([])
    useEffect(() => {
        let arr:Array<any>=[]
        list.forEach((item,index)=>{
            arr[index]=(Math.random() * 40 + 20).toFixed(0)+'ms'
        })
        SetSpeedList(arr)
    }, [])
    return (
        <div className="arrdv9-0 kaxIlA">
            <Header></Header>
                <div className="yield">
                    <div className="back-wrap">
                        <h3>备用网址</h3>
                        <p className="subtitle">截图保存
                        </p>
                        <div className="back-list">
                            <ul>
                                {
                                    list.map((item:any,index)=>{
                                        return( <li key={index} className="list-item">
                                            <div className="url-left">
                                                {item}
                                            </div>
                                            <div className="test-time">
                                                {speedList[index]}
                                            </div>
                                            <div className="entry">
                                                <a target="_blank" href={item}>进入</a>
                                            </div>
                                        </li>)
                                    })
                                }
                            </ul>
                        </div>
                    </div>
                </div>
            <Footer></Footer>
        </div>
    )
}

export default Backurl
