import {Modal, Toast} from 'antd-mobile'
import {useNavigate, useParams} from 'react-router-dom'
import Header from "@/components/header";
import React, {useEffect, useRef, useState} from 'react'
import {useSelector} from "react-redux";
import {RootState} from "@/types/store";
import sdk from "@/service/sdk"
import VerifyCode from "@/components/VerifyCode";
import Footer from "@/components/Footer";
import {setToken} from "@/utils/storage";
import emitter from "@/utils/events";
const Account = () => {
    const history = useNavigate()
    const navigate = useNavigate()
    const [phone, SetPhone] = useState('')
    const [code, SetCode] = useState('')

    const userInfo = useSelector(
        (state: RootState) => {
            return state.app.userInfo
        }
    )
    const submit=()=>{
        if (!phone)
        {
           return Toast.show({
               content: '请输入手机号!'
           })
        }
        if (!code)
        {
            return Toast.show({
                content: '请输入验证码!'
            })
        }
        Toast.show({
            icon: 'loading',
            content: 'loading…',
            duration:0
        })
        sdk.bindPhone(phone,code).then((res)=>{
            if (res.status==1)
            {
                Toast.show({
                    icon: 'success',
                    content: '手机号绑定成功!',
                    afterClose:()=>{
                        navigate(-1)
                    }
                })
            }
        })
    }
    const getCode = (call:Function) => {
        call?.()
    }
    return (<div className="arrdv9-0 kaxIlA mobile-page">
            <Header></Header>
            <div className="mobile-content">
                <div className="Structure-sc-122ascm-0 kUYGou mobile-header">
                    <div className="Structure-sc-122ascm-0 gCNTYn">
                     <h2   className="Heading__H2-sc-1lip0t2-1 bbatoq Heading-sc-1icgkho-0 cVvOCk">绑定手机
                    </h2>
                    </div>
                </div>
                <div className="Structure-sc-122ascm-0 fPVyDJ">
                    <div className="Structure-sc-122ascm-0 kUYGou">
                        <div className="Input__Wrapper-sc-afb25n-0 BMDUb">
                            <div className="Input__InputWrapper-sc-afb25n-3 jSzqLK">
                                <input maxLength={11} minLength={11} value={phone}
                                       onChange={(e) => SetPhone(e.target.value)} type="text" id="current-password"
                                       name="current-password"
                                       className="Input-sc-179fhke-0 Input__InputElement-sc-afb25n-6 irDggH NXyZC" />
                            </div>
                            <label  className="Input__Label-sc-afb25n-1 jJQUgq">手机号</label>
                        </div>
                    </div>
                    <div className="Structure-sc-122ascm-0 feuTYo">
                        <div className="Input__Wrapper-sc-afb25n-0 BMDUb">
                            <div className="Input__InputWrapper-sc-afb25n-3 jSzqLK">
                                <input value={code}
                                       onChange={(e) => SetCode(e.target.value)} type="text" id="new-password" name="new-password"
                                       className="Input-sc-179fhke-0 Input__InputElement-sc-afb25n-6 irDggH NXyZC" />
                                    <div  className="Input__After-sc-afb25n-5 eGBVkV">
                                        <VerifyCode type={1} phone={phone} seconds={60} onClick={getCode} />
                            </div>
                        </div>
                        <label className="Input__Label-sc-afb25n-1 jJQUgq">验证码</label>
                    </div>
                </div>
            </div>
                <div className="Structure-sc-122ascm-0 iVbMDf">
                    <div onClick={()=>{
                        submit()
                    }} className="Structure-sc-122ascm-0 dKmixv">
                        <button  type="submit"
                         className="Button-sc-uvpcy4-0 Button-sc-1nfhkv6-0 kwplws dxPGQA">绑定
                    </button>
                </div>
            </div>
            </div>
            <Footer></Footer>
        </div>
    )
}

export default Account
