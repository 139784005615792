import produce from 'immer'

type HomeStateType = {

}

const HomeState: HomeStateType = {
  articles: {},
}

const home = produce((draft, action) => {
  switch (action.type) {
    default:
      break
  }
}, HomeState)

export default home
