import {Modal} from "antd-mobile";
import React, {useState} from "react";
import sdk from "@/service/sdk";
import { Toast } from 'antd-mobile';
import {
    TextArea,
    Button
} from 'antd-mobile'

type Props = {
    id:any
}
const Content=({id}:Props)=>{
    const [content,SetContent] = useState('')
    const submit=()=>{
        Toast.show({
            icon: 'loading',
            content: 'loading…',
            duration:0
        })
        sdk.sendFeedback(id,content).then((res)=>{
            if (res.status==1)
            {
                Toast.show({
                    icon: 'success',
                    content: '发送成功',
                })
            }
            Modal.clear()
        })

    }
    return(<div className="modal-wrap">
        <div  className="form-item">
            <div className="form-item-inner">
                <TextArea
                    onChange={val => {
                        SetContent(val)
                    }}
                    value={content}
                    placeholder='请输入反馈信息'
                    maxLength={100}
                    rows={2}
                    showCount
                />
            </div>
            <Button  block color='primary' onClick={()=>{
                submit()
            }} size='large'>
                提交
            </Button>
        </div>
    </div>)
}
const Feedback = (id:any) => {


    Modal.show({
        title: '问题反馈',
        content:<Content id={id}></Content>,
        showCloseButton: true,
        className:'recharge-modal'
    })
}

export default Feedback
