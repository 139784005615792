import {useEffect, useState,useRef} from "react";
import sdk from "@/service/sdk";
import { Toast } from 'antd-mobile'
type Props = {
    onClick?:Function,
    seconds:number,
    phone:string,
    type:number
}
const VerifyCode = ({ onClick, seconds = 60,phone,type}:Props) => {
    const [time, setTime] = useState(0)
    const timer = useRef<any>(null)
    const [isDisabled, SetConIsDisabled] = useState(false)

    useEffect(() => {
        timer.current && clearInterval(timer.current);
        SetConIsDisabled(false)
    }, []);

    useEffect(()=> {
        if( time === seconds ) timer.current = setInterval(()=> setTime(time => --time), 1000)
        else if ( time <= 0 ){
            timer.current && clearInterval(timer.current)
            SetConIsDisabled(false)
        }
    }, [time])

    const getCode = () => {
        if (!phone) return Toast.show({
            content: '请输入手机号'
        });
        if (time) return;
        // 作为组件使用
        onClick?.(()=> {
            setTime(seconds)
        })
        //直接使用
        setTime(seconds)
        SetConIsDisabled(true)
        Toast.show({
            icon: 'loading',
            content: 'loading…',
            duration:0
        })
        if (type==1)
        {
            sdk.sendCode(phone)
        }
        else {
            sdk.sendLoginCode(phone)
        }

    }

    return (
    <button disabled={isDisabled} onClick={getCode} type="button"  className="Button-sc-uvpcy4-0 Password__Button-sc-1ef9qcw-0 kwplws eDWyDf">
        { time? `${time}秒后获取`: '获取验证码' }
    </button>
    )
}
export default VerifyCode;