import React, {useEffect, useRef, useState} from 'react'
import {useNavigate} from "react-router-dom";
import Hls from 'hls.js';
import './player.scss';
import Plyr from 'plyr'
import 'plyr/dist/plyr.css'
import { VideoInfo} from '@/types/data'
import {get_image} from "@/utils/common";
import sdk from "@/service/sdk";
import BuyModal from "@/components/BuyModal";
import loadingImg from "@/assets/images/loading.gif";
interface Props {
 info:VideoInfo,
 loading:boolean
}
const Player: React.FC<Props> = ({info,loading}: Props) => {
    const navigate = useNavigate()
    const ref = useRef<HTMLVideoElement|null>(null)
    const [poster, SetPoster] = useState('')
    const [show, SetShow] = useState(false)
    let videoUrl=info.video_url
    useEffect(() => {
        if (info.cover_url)
        {
            sdk.getImage(info.cover_url).then((res)=>{
                SetPoster(get_image(res))
            })
        }
        if (info.need_pay)
        {
            SetShow(true)
        }
        var videoElem = document.getElementById('player') as HTMLVideoElement
        let hls: Hls
        if (!info.need_pay)
        {
            if (videoUrl&&videoUrl.slice(-4) === 'm3u8') {
                var hlsUrl = videoUrl
                if (Hls.isSupported()) {
                    hls = new Hls()
                    hls.loadSource(hlsUrl)
                    hls.attachMedia(videoElem)
                } else if (videoElem.canPlayType('application/vnd.apple.mpegURL')) {
                    let newSource = document.createElement('source')
                    newSource.src = videoUrl
                    newSource.type = 'application/vnd.apple.mpegURL'
                    videoElem.appendChild(newSource)
                } else {
                    alert('对不起,你的设备无法播放该m3u8类型视频, 换个视频试试吧.')
                }
            }
        }
        let p = new Plyr(videoElem, {
            ratio: '16:9',
            fullscreen: { enabled: true, fallback: true, iosNative: true },
            // ads: { enabled: false, tagUrl: "" },
            // previewThumbnails: { enabled: true, src: vttUrl },
        })
        return () => {
            p.destroy()
            hls?.destroy()
        }
    }, [videoUrl])
    return (
        <>
            <div className="video-wrap" key={info.id}>
                <video
                    ref={ref}
                    poster={poster}
                    id="player"
                    playsInline
                    controls
                    onPlay={() => {
                        if (info.need_pay)
                        {
                            SetShow(true)
                        }
                    }}
                    onPause={() => {

                    }}

                >
                    <div className='watermark'>
                        <img src={poster}/>
                    </div>
                    {videoUrl?((videoUrl.slice(-4) === '.mp4') && (
                        <source src={videoUrl} type="video/mp4" />
                    )):null}
                </video>
                {
                    loading?<div className="loading">
                        <img src={loadingImg} />
                    </div>:<></>
                }

            </div>
            {
                show?<BuyModal close={()=>{
                    SetShow(false)
                }} key={'modal'} info={info} show={show}></BuyModal>:<></>
            }

        </>
    )
}

export default React.memo(Player)
