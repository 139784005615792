import React, {useEffect, useState} from 'react'
import {useNavigate} from "react-router-dom";
import styles from './index.module.scss'
import classnames from "classnames";
import {LeftOutline, RightOutline} from 'antd-mobile-icons'

type Props = {
    total?: number,
    pageSize?: number,
    change:(page:number) => void
}
const Pagination = ({total = 80, pageSize = 10,change}: Props) => {
    const history = useNavigate()
    let pages = Math.ceil(total / pageSize)
    const [list, SetList] = useState<any>([] as any)
    const [currentPage, SetCurrentPage] = useState<any>(1)
    useEffect(() => {
        countList()
    }, [currentPage])
    const countList = () => {
        // 当父组件传递total的值发生变化时，计算属性会重新计算
        pages = Math.ceil(total / pageSize)
        const result:number[] = []
        // 总页码小于等于5；大于5
        if (pages <= 5) {
            // 总页码小于等于5的情况
            for (let i = 1; i <= pages; i++) {
                result.push(i)
            }
        } else {
            // 总页码大于5
            if (currentPage <= 2) {
                // 左侧临界值
                for (let i = 1; i <= 5; i++) {
                    result.push(i)
                }
            } else if (currentPage >= pages - 1) {
                // 右侧临界值
                for (let i = pages - 4; i <= pages; i++) {
                    result.push(i)
                }
            } else {
                // 中间的状态
                for (let i = currentPage - 2; i <= currentPage + 2; i++) {
                    result.push(i)
                }
            }
        }
        SetList(result)
    }
    // 控制上一页和下一页变化
    const changePage = async (type:number) => {
        let current=type
        if (type === -1) {
            // 上一页
            // 页面是第一页时，禁止点击操作
            if (currentPage === 1) return
            if (currentPage > 1) {
              await  SetCurrentPage(currentPage-1)
                current=currentPage-1
                change(current)
            }
        } else if (type === 0) {
            // 下一页
            // 页面是最后页时，禁止点击操作
            if (currentPage === pages) return
            if (currentPage < pages) {
               await SetCurrentPage(currentPage+1)
                current=currentPage+1
                change(current)
            }
        } else {
            // 点击页码
          await  SetCurrentPage(type)
            current=type
            change(current)
        }
        countList()
        window.scrollTo(0,0)
    }
    return (
        <div className={classnames(styles.root, 'page-wrap')}>
            <div className="page-content">
                <div onClick={()=>{
                    changePage(-1)
                }} className="page-previous">
                    <LeftOutline color="#e0e0e0" fontSize={20}/>
                </div>
                <div className="page-list">
                    {
                        list.map((item: any, index:number) => {
                            return (<a onClick={()=>{
                                changePage(item)
                            }} key={index} className={currentPage==item?'page-item page-current':'page-item'}>
                      <span className="page-item-number">
                          {item}
                      </span>
                            </a>)
                        })
                    }
                </div>
                <div onClick={()=>{
                    changePage(0)
                }} className="page-last">
                    <RightOutline color="#e0e0e0" fontSize={20}/>
                </div>
            </div>
        </div>
    )
}

export default React.memo(Pagination)
