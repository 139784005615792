import {Link, useNavigate, useParams} from 'react-router-dom'
import Icon from '@/components/icon'
import styles from './index.module.scss'
import React, {useEffect, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {RootState} from '@/types/store'
import Header from "@/components/header";
import VideoCard from "@/components/VideoCard";
import {VideoInfo} from "@/types/data";
import sdk from "@/service/sdk";
import Pagination from "@/components/Pagination";
import Footer from "@/components/Footer";
import {Toast,Empty} from 'antd-mobile';
import { v4 as uuidv4 } from 'uuid';

const Column = () => {
    const [list, SetList] = useState([])
    const [total, SetTotal] = useState(0)
    const [isLoading, SetIsLoading] = useState(false)
    const [pageSize, SetPageSize] = useState(10)
    const history = useNavigate()
    useEffect(() => {
        SetList([])
        getList(
            1
        )
    }, [])
    const getList = (page: number) => {
        SetList([])
        Toast.show({
            icon: 'loading',
            content: 'loading…',
            duration: 0
        })
        SetIsLoading(true)
        sdk.getCollectVideo( page, 20).then((res) => {
            if (res.status == 1) {
                SetList(res.data.video_list)
                SetTotal(res.data.pagination.total)
            }
            SetIsLoading(false)
        })
    }
    return (
        <div className="arrdv9-0 kaxIlA">
            <Header></Header>
            <div className="sc-eCjkpP iDHATf mt-20">
                <div className="sc-clhSjD yLshi">
                    {
                        list.length&&!isLoading?list.map((item: any, index) => {
                            return (
                                <VideoCard  info={item} key={uuidv4()}></VideoCard>
                            )
                        }):(!isLoading?<Empty description='暂无数据' />:<></>)
                    }

                </div>
                {
                    total ?
                        <Pagination pageSize={pageSize} key={total} total={total} change={getList}></Pagination> : <></>
                }
            </div>
            <Footer></Footer>
        </div>
    )
}

export default React.memo(Column)
