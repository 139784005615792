import { createStore, applyMiddleware } from 'redux'
import { persistStore, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage' // defaults to localStorage for web
import { composeWithDevTools } from 'redux-devtools-extension'
import thunk from 'redux-thunk'
import reducer from './reducers'

const persistConfig = {
    key: 'root',
    storage,
}
const persistedReducer = persistReducer(persistConfig, reducer)
    const store = createStore(
        persistedReducer,
        process.env.NODE_ENV === 'development'
            ? composeWithDevTools(applyMiddleware(thunk))
            : applyMiddleware(thunk)
    )
export const persistor = persistStore(store)
export default store