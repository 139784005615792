import React, {useEffect, useState} from 'react'
import {changeMenuState} from '@/store/actions/app';
import {RootState} from "@/types/store";
import './header.css'
import {useSelector, useDispatch} from "react-redux";
import Icon from "@/components/icon";
import {Link, useNavigate , useParams} from "react-router-dom";
import {useLocation} from "react-router";
import avatar from '@/assets/images/avatar.png';
import {Collapse, Tabs} from 'antd-mobile'
import LoginModal from "@/components/LoginModal";
import events from "node:events";
import logo from '@/assets/images/logo.png';
import sdk from "@/service/sdk";
import Marquee from "@/components/Marquee";

interface Props {

}

const Header: React.FC<Props> = (props: Props) => {
    const dispatch = useDispatch()
    const [keyword, SetKeyword] = useState('')
    const [tabList,cateList,linkList,topText] = useSelector(
        (state: RootState) => [state.app.indexData.tab_list,state.app.category,state.app.menu,state.app.topText]
    )
    // 获取地址里的id值
    let { id,cid } = useParams<{ id: string,cid:string }>()
    const [catId, SetCatId] = useState(cid)
    const [config] = useSelector(
        (state: RootState) => {
            return [state.app.config]
        }
    )
    const {showMenu} = useSelector(
        (state: RootState) => state.app
    )
    const hide = () => {
        dispatch(changeMenuState(false))
    }
    const getCid=()=>{
        if (location.pathname.indexOf('tab')!=-1)
        {
                for (let i=0;i<cateList.length;i++)
                {
                    if (cateList[i].id==id)
                    {
                        return cateList[i].children_list[0].id
                    }
                }
        }
        else if (location.pathname=='/home'||location.pathname=='/')
        {
            for (let i=0;i<cateList.length;i++)
            {
                if (cateList[i].id=='area')
                {
                    return cateList[i].children_list[0].id
                }
            }
        }
        else {
            return cid
        }
    }
    const show = () => {
        dispatch(changeMenuState(true))
        setTimeout(()=>{
            const el:any=document.getElementById('idSrqE')
            const el2:any=document.getElementById('bbeARo')
            el.addEventListener('touchmove',(e:any)=>{
                e.stopPropagation()
            }, { passive: false })
            el2.addEventListener('touchmove',(e:any)=>{
                e.stopPropagation()
            }, { passive: false })


        })
    }
    const userInfo = useSelector(
        (state: RootState) => {
            return state.app.userInfo
        }
    )
    const location = useLocation()
    const navigate = useNavigate()
    const toLink=(path:string)=>{
        hide()
        navigate(path)
        window.scrollTo(0,0)
    }
    const handleSearch=(event:any)=>{
        if (event.keyCode === 13&&keyword) {
            hide()
            navigate(`/search/${keyword}`,{ replace: true })
            SetKeyword('')
        }
    }
    const getCategory=(key:string)=>{
        cateList.forEach((item:any)=>{
            if (location.pathname=='/home'||location.pathname=='/')
            {
                if (item.id=='area')
                {
                    return item.children_list.forEach((it:any)=>{
                        if (it.id==key)
                        {
                            navigate(`/category/${'area'}/${key}/${item.name}/${it.name}`)
                            window.scrollTo(0,0)
                        }
                    })
                }
            }
            else {
                if (item.id==id)
                {
                    return item.children_list.forEach((it:any)=>{
                        if (it.id==key)
                        {
                            navigate(`/category/${id}/${key}/${item.name}/${it.name}`)
                            window.scrollTo(0,0)
                        }
                    })
                }
            }

        })
    }
    return (
        <div id='header' className="fk73ya-7 cJgFrV">
            <div className="t2pl8x-0 cEwOrG navbar navbar-default navbar-fixed-top">
                <div className="sc-11mqo2t-0 iycrvY">
                    <Marquee list={topText}></Marquee>
                </div>
                <div className="fk73ya-0 gpYNJZ container no-pad">
                    {
                        showMenu ? <div className="fk73ya-6 bFCfqA cbssWR active" onClick={() => {
                            hide()
                        }}>
                            <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="arrow-left"
                                 className="svg-inline--fa fa-arrow-left fa-w-14 " role="img"
                                 xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                                <path fill="currentColor"
                                      d="M257.5 445.1l-22.2 22.2c-9.4 9.4-24.6 9.4-33.9 0L7 273c-9.4-9.4-9.4-24.6 0-33.9L201.4 44.7c9.4-9.4 24.6-9.4 33.9 0l22.2 22.2c9.5 9.5 9.3 25-.4 34.3L136.6 216H424c13.3 0 24 10.7 24 24v32c0 13.3-10.7 24-24 24H136.6l120.5 114.8c9.8 9.3 10 24.8.4 34.3z"></path>
                            </svg>
                        </div> : <div className="fk73ya-6 cbssWR" onClick={() => {
                            show()
                        }}>
                            <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="bars"
                                 className="svg-inline--fa fa-bars fa-w-14 " role="img"
                                 xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                                <path fill="currentColor"
                                      d="M16 132h416c8.837 0 16-7.163 16-16V76c0-8.837-7.163-16-16-16H16C7.163 60 0 67.163 0 76v40c0 8.837 7.163 16 16 16zm0 160h416c8.837 0 16-7.163 16-16v-40c0-8.837-7.163-16-16-16H16c-8.837 0-16 7.163-16 16v40c0 8.837 7.163 16 16 16zm0 160h416c8.837 0 16-7.163 16-16v-40c0-8.837-7.163-16-16-16H16c-8.837 0-16 7.163-16 16v40c0 8.837 7.163 16 16 16z"></path>
                            </svg>
                        </div>
                    }
                    <a onClick={()=>{
                        toLink("/home")
                    }} className="logo active">
                        <img  src={logo} alt="Straplez" />
                    </a>
                    <div className="inline-block va-m pull-right header-right">
                        <div className="inline-block profile-icons">
                            <div className="hidden-xs hidden-sm inline-block">
                                <a className="btn btn-primary join-btn" onClick={()=>{
                                    toLink("/recharge")
                                }}>
                                    加入会员
                                </a>
                              {/*  <a className="btn btn-primary join-btn">加入会员</a>*/}
                            </div>
                            <a onClick={()=>{
                                toLink("/recharge")
                            }} className="hidden-md hidden-lg hidden-xl join-btn guest-btns">
                                加入会员
                            </a>
                            {/*<a className="user-avatar">
                                <img src={avatar}/>
                            </a>*/}
                            {/*<div className="right-btns">
                                <div>
                                    <div className="fk73ya-1 fk73ya-2 kwPtnk fgyqDz hidden-xs hidden-sm clickable">
                                        <i className="manicon manicon-search-icon" aria-hidden="true"></i></div>
                                </div>
                            </div>*/}
                        </div>
                    </div>
                    {
                        <section id='idSrqE' className={showMenu?'ozusjm-0 idSrqE entered mobile-menu-drawer':'ozusjm-0 idSrqE exiting mobile-menu-drawer'}>
                            <div id='bbeARo' className="sc-1hp8bib-1 bbeARo mobile-menu">
                                <div className="sc-fbNXWD gqNXNr form-nav-search form-inline">
                                    <div className="sc-fvhGYg DFPHx">
                                        <svg onClick={(e)=>{
                                            if (keyword)
                                            {
                                                hide()
                                                navigate(`/search/${keyword}`,{ replace: true })
                                                SetKeyword('')
                                            }
                                        }} aria-hidden="true" focusable="false" data-prefix="fas" data-icon="search"
                                             className="svg-inline--fa fa-search fa-w-16 " role="img"
                                             xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                                            <path fill="currentColor"
                                                  d="M505 442.7L405.3 343c-4.5-4.5-10.6-7-17-7H372c27.6-35.3 44-79.7 44-128C416 93.1 322.9 0 208 0S0 93.1 0 208s93.1 208 208 208c48.3 0 92.7-16.4 128-44v16.3c0 6.4 2.5 12.5 7 17l99.7 99.7c9.4 9.4 24.6 9.4 33.9 0l28.3-28.3c9.4-9.4 9.4-24.6.1-34zM208 336c-70.7 0-128-57.2-128-128 0-70.7 57.2-128 128-128 70.7 0 128 57.2 128 128 0 70.7-57.2 128-128 128z"></path>
                                        </svg>
                                        <input value={keyword}
                                               onKeyDown={(e)=>{handleSearch(e)}}
                                               onChange={(e) => SetKeyword(e.target.value)} name="searchTerm" type="text" placeholder="搜索"
                                               className="form-control search-term" autoComplete="off"  />
                                        </div>
                                    </div>
                                <Collapse defaultActiveKey={['1']}>
                                    <Collapse.Panel key='1' title={`账号:`+userInfo.username}>
                                        <div onClick={()=>{
                                            toLink(`/account`)
                                        }} className="panel-item">
                                            个人中心
                                        </div>
                                        <div className="panel-item">
                                            密码:{userInfo.password}
                                        </div>
                                        <div onClick={()=>{
                                            hide()
                                            LoginModal()
                                        }} className="panel-item">
                                            切换账号
                                        </div>
                                        <div onClick={()=>{
                                            toLink(`/phone`)
                                        }} className="panel-item">
                                            绑定手机
                                        </div>
                                        <div onClick={()=>{
                                            toLink("/collect")
                                        }} className="panel-item">
                                            我的收藏
                                        </div>
                                        <div onClick={()=>{
                                            toLink("/purchased")
                                        }} className="panel-item">
                                            我的购买
                                        </div>
                                        <div onClick={()=>{
                                            toLink("/backurl")
                                        }} className="panel-item">
                                            备用网址
                                        </div>
                                        <div onClick={()=>{
                                            toLink("/message")
                                        }} className="panel-item">
                                            消息回复
                                        </div>
                                        <div onClick={()=>{
                                            window.open(config.service_url,'_blank')
                                        }} className="panel-item">
                                            在线客服
                                        </div>
                                    </Collapse.Panel>
                                    {
                                        cateList.map((item:any,index)=>{
                                            return (
                                                <Collapse.Panel key={item.id+''+index} title={item.name}>
                                                    {
                                                        item.children_list.map((it:any,idx:number)=>{
                                                            return(<div key={it.id+''+idx+item.id} onClick={()=>{
                                                                toLink(`/category/${item.id}/${it.id}/${item.name}/${it.name}`)
                                                            }} className="panel-item">
                                                                    {it.name}
                                                            </div>)
                                                        })
                                                    }
                                                </Collapse.Panel>
                                            )
                                        })
                                    }
                                </Collapse>
                                {
                                    linkList.map((item:any,index)=>{
                                       return(<a key={index+'menu'} href={item.redirect_url} target="_blank" className="adm-list-item adm-plain-anchor adm-collapse-panel-header link-item">
                                           <div className="adm-list-item-content">
                                               <div className="adm-list-item-content-main">{
                                                   item.title
                                               }</div>
                                           </div>
                                       </a>)
                                    })
                                }
                            </div>
                        </section>
                    }

                </div>
                <div className="nav-header">
                   <div className={location.pathname=='/home'||location.pathname=='/'?'nav-item active':'nav-item'}>
                       <Link to="/home">
                           推荐
                       </Link>
                   </div>
                    {
                        tabList.map((item:any,index)=>{
                            return (<div key={index} className={id==item.id?'nav-item active':'nav-item'}>
                                <a onClick={()=>{
                                    toLink(`/tab/${item.id}`)
                                }}>
                                    {item.name}
                                </a>
                            </div>)
                        })
                    }
                    {/*<div className="nav-item">
                        <Link to="/home">
                            加入会员
                        </Link>
                    </div>*/}
                </div>
            </div>
            {
                location.pathname.indexOf('category')!=-1||(location.pathname.indexOf('tab')!=-1&&(id!='free'))||location.pathname=='/home'||location.pathname=='/'?<Tabs key={catId} activeKey={getCid()} onChange={getCategory}>
                    {
                        cateList.map((item:any)=>{
                            if (location.pathname=='/home'||location.pathname=='/')
                            {

                                if (item.id=='area')
                                {
                                    return item.children_list.map((it:any)=>{
                                        return (
                                            <Tabs.Tab  title={it.name} key={it.id}>
                                            </Tabs.Tab>
                                        )
                                    })
                                }
                            }
                            else {
                                if (item.id==id)
                                {
                                    return item.children_list.map((it:any)=>{
                                        return (
                                            <Tabs.Tab  title={it.name} key={it.id}>
                                            </Tabs.Tab>
                                        )
                                    })
                                }
                            }

                        })
                    }
                </Tabs>:<></>
            }

            {
                showMenu?<div className="t2pl8x-2 iyioUw" onClick={() => {
                    hide()
                }}></div>:<></>
            }

        </div>
    )
}

export default React.memo(Header)
