import { BrowserRouter,createHashRouter,RouterProvider,Routes, Route,useBeforeUnload,useRoutes,useBlocker} from "react-router-dom";
import KeepAlive from 'react-activation'
import "nprogress/nprogress.css";
import NProgress from "nprogress";
import './App.scss'
import './assets/styles/main.css'
import './assets/styles/app.css'
import React, {Suspense,useEffect} from 'react'
import { useDispatch,useSelector } from 'react-redux'
import Home from '@/pages/Home'
import {getCategory, registerUser, getUserInfo, changeMenuState,saveConfig,setHeader,setQuestions} from "@/store/actions/app";
import { removeToken, setToken,getToken } from "@/utils/storage";

import NotFound from '@/pages/NotFound'
import Tab from '@/pages/Tab'
import Video from '@/pages/Video'
import Account from '@/pages/Account'
import Recharge from '@/pages/Recharge'
import Phone from '@/pages/Phone'
import Search from '@/pages/Search'
import Column from '@/pages/Column'
import Collect from '@/pages/Collect'
import Purchased from '@/pages/Purchased'
import Backurl from '@/pages/Backurl'
import Message from '@/pages/Message'
import emitter from '@/utils/events'
import { AliveScope,withAliveScope, useAliveController,useActivate, useUnactivate, withActivation } from 'react-activation'
function isObjectEmpty(obj: object): boolean {
    return Object.keys(obj).length === 0;
}
/*NProgress.configure({showSpinner: false})*/
function App() {
    const dispatch = useDispatch()
    const token = getToken()
    useEffect(() => {
        dispatch(getCategory())
        dispatch(saveConfig())
        dispatch(setHeader())
        dispatch(setQuestions())
        const eventEmitter:any = emitter.addListener("reload", (msg) => {
            dispatch(getUserInfo())
        });
        if (!token||isObjectEmpty(token))
        {
            dispatch(registerUser())

        }
        else {
            dispatch(getUserInfo())
        }
        dispatch(changeMenuState(false))
        return () => {
            emitter.removeListener(eventEmitter,[] as any);
        };
    }, [])
    const router = createHashRouter([
        {
            path: "/",
            element:(<KeepAlive id="/home">
                <Home></Home>
            </KeepAlive>),
            loader: ({ params }) => {
                /*NProgress.start()*/
                return null
            }
        },
        {
            path: "/home",
            element:(<KeepAlive id="/home">
                <Home></Home>
            </KeepAlive>),
            loader: ({ params }) => {
                /*NProgress.start()*/
                return null
            },
        },
        {
            path: "/tab/:id",
            element:(<KeepAlive id="/tab">
                <Tab />
            </KeepAlive>),
        },
        {
            path: "/category/:id/:cid/:name/:cname",
            element:(<KeepAlive id="/category">
                <Tab />
            </KeepAlive>),
        },
        {
            path: "/column/:id/:name",
            element:(<KeepAlive id="/column">
                <Column />
            </KeepAlive>),
        },
        {
            path: "/video/:id",
            element: <Video />,
        },
        {
            path: "/search/:key",
            element: (<KeepAlive id="/search">
                <Search />
            </KeepAlive>),
        },
        {
            path: "/account",
            element: <Account />,
        },
        {
            path: "/recharge",
            element: <Recharge />,
        },
        {
            path: "/phone",
            element: <Phone />,
        },
        {
            path: "/backurl",
            element: <Backurl />,
        },
        {
            path: "/collect",
            element: <Collect />,
        },
        {
            path: "/purchased",
            element: <Purchased />,
        },
        {
            path: "/message",
            element: <Message />,
        },
    ]);
    return (
        <AliveScope>
            <RouterProvider router={router}/>
        </AliveScope>
    );
}

export default App
