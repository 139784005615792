import {Modal, Tabs,Button,Toast} from "antd-mobile";
import React, {useState} from "react";
import VerifyCode from "@/components/VerifyCode";
import sdk from "@/service/sdk";
import {setToken} from "@/utils/storage";
import emitter from '@/utils/events'
const LoginForm=()=>{
    const [phone, SetPhone] = useState('')
    const [code, SetCode] = useState('')
    const [username, SetUsername] = useState('')
    const [password, SetPassword] = useState('')
    const submit=(type:number)=>{
        if (type==1)
        {
            if (!username)
            {
                return Toast.show({
                    content: '请输入用户名!'
                })
            }
            if (!password)
            {
                return Toast.show({
                    content: '请输入用户密码!'
                })
            }
            Toast.show({
                icon: 'loading',
                content: 'loading…',
                duration:0
            })
           sdk.loginByUsername(username,password).then((res)=>{
               if (res.status==1)
               {
                   Toast.show({
                       icon: 'success',
                       content: '登录成功!',
                       afterClose:()=>{
                           Modal.clear()
                           setToken(res.data.token)
                           emitter.emit("reload");
                       }
                   })
                   /*window.location.reload()*/
               }
           })
        }
        else {
            if (!phone)
            {
                return Toast.show({
                    content: '请输入手机号码!'
                })
            }
            if (!code)
            {
                return Toast.show({
                    content: '请输入验证码!'
                })
            }
            Toast.show({
                icon: 'loading',
                content: 'loading…',
                duration:0
            })
            sdk.loginByPhoneNumber(phone,code).then((res)=>{
                if (res.status==1)
                {
                    Toast.show({
                        icon: 'success',
                        content: '登录成功!',
                        afterClose:()=>{
                            Modal.clear()
                            setToken(res.data.token)
                            emitter.emit("reload");
                        }
                    })
                    /*window.location.reload()*/
                }
            })
        }
    }
    return  (<div className="modal-wrap switch-modal">
        <Tabs>
            <Tabs.Tab title='手机登录' key='fruits'>
                <p className="subtitle">通过已绑定的手机号登陆帐号</p>
                <div className="modal-form">
                    <div className="form-group required">
                        <label>手机号</label>
                        <div className="form-flex">
                            <div className="phone">
                                <input value={phone}
                                       onChange={(e) => SetPhone(e.target.value)} id="p-phone" className="form-control"/>
                            </div>
                            <div className="Input__After-sc-afb25n-5 eGBVkV">
                                <VerifyCode type={2} phone={phone} seconds={60}></VerifyCode>
                            </div>
                        </div>
                    </div>
                    <div className="form-group required">
                        <label>验证码</label>
                        <input value={code}
                               onChange={(e) => SetCode(e.target.value)} id="p-gold" className="form-control"/>
                    </div>
                </div>
                <Button onClick={()=>{
                    submit(2)
                }} className="login-btn" block color='primary' size='middle'>
                    切换账号
                </Button>
            </Tabs.Tab>
            <Tabs.Tab title='用户名登录' key='vegetables'>
                <p className="subtitle">通过用户名密码登陆帐号</p>
                <div className="modal-form">
                    <div className="form-group required">
                        <label>帐号</label>
                        <div className="phone">
                            <input value={username}
                                   onChange={(e) => SetUsername(e.target.value)} id="p-name" className="form-control" />
                        </div>
                    </div>
                    <div className="form-group required">
                        <label>密码</label>
                        <input value={password}
                               onChange={(e) => SetPassword(e.target.value)} type="password" id="p-password" className="form-control" />
                    </div>
                </div>
                <Button onClick={()=>{
                    submit(1)
                }} className="login-btn" block color='primary' size='middle'>
                    切换账号
                </Button>
            </Tabs.Tab>
        </Tabs>
    </div>)
}
const LoginModal = () => {
    Modal.show({
        title: '切换账号',
        content:<LoginForm/>,
        showCloseButton: true,
        className:'login-modal'
    })
}

export default LoginModal
