import axios, { AxiosResponse, AxiosInstance, AxiosRequestConfig } from 'axios'
import {Encrypt,Decrypt,DecryptImg} from '@/utils/crypto';
import {Toast } from 'antd-mobile'
import Cookies from "js-cookie";
export interface SuccessFormat {
  status?: number;
  data?: any;
}

const handleError = (err: any) => {
  let msg = err.message || err.msg || ''
  Toast.clear()
  Toast.show({
    content: `${msg}`
  })
  Cookies.remove('api')
}

const handleResponse = (res: AxiosResponse, resolve: (res: any) => void) => {
  const response: SuccessFormat =res.data
  /*NProgress.start();*/
  if (response.hasOwnProperty('status')) {
    if (response.status==1) {
      // const { success, ...rest } = response
      resolve(response)
      Toast.clear()
    } else {
      handleError(response)
    }
  } else {
    handleError(response)
  }
  if (res.status!=200&&(res.status!=404)&&(res.status!=500))
  {
    Cookies.remove('api')
  }

}

class SDK {
  $http: AxiosInstance;

  constructor(config: AxiosRequestConfig) {
    this.$http = axios.create(config || {})
  }


  get (url: string, params?: object): Promise<SuccessFormat> {
    return new Promise((resolve, reject) => {
      this.$http.get(url, { params })
        .then(res => handleResponse(res, resolve))
        .catch(handleError)
    })
  }

  post (url: string, data?: object): Promise<SuccessFormat> {
    return new Promise((resolve, reject) => {
      this.$http.post(url, data)
        .then(res => handleResponse(res, resolve))
        .catch(handleError)
    })
  }
  postImg (url: string): Promise<SuccessFormat> {
    return new Promise((resolve, reject) => {
      this.$http.get(url,{responseType:'arraybuffer'})
          .then(res => resolve(res.data))
          .catch(handleError)
    })
  }

}

export default SDK
