import {Link, useNavigate, useParams,useNavigationType} from 'react-router-dom'

import Icon from '@/components/icon'
import styles from './index.module.scss'
import React, {useEffect, useState,useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { v4 as uuidv4 } from 'uuid';
import { RootState } from '@/types/store'
import Header from "@/components/header";
import VideoCard from "@/components/VideoCard";
import {VideoInfo} from "@/types/data";
import sdk from "@/service/sdk";
import Pagination from "@/components/Pagination";
import { Toast } from 'antd-mobile';
import Footer from "@/components/Footer";
import {useActivate} from "react-activation";
const Column = () => {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const navigationType =useNavigationType()
    const [list, SetList] = useState([])
    const [total, SetTotal] = useState(0)
    const [pageSize, SetPageSize] = useState(10)
    // 获取地址里的id值
    const { id,name } = useParams<{ id: string,name:string }>()
    useEffect(() => {
        SetList([])
        getList(
            1
        )
        window.scrollTo(0,0)
    }, [id])
    useActivate(()=>{
        if (navigationType!=='POP')
        {
            SetList([])
            getList(
                1
            )
        }
        else {
            window.scrollTo(0,0)
        }
    })
    const getList=(page:number,type=0)=>{
        SetList([])
        Toast.show({
            icon: 'loading',
            content: 'loading…',
            duration:0
        })
        sdk.getColumnList(id,page,20).then((res) => {
                if (res.status==1)
                {
                    SetList(res.data.video_list)
                    SetTotal(res.data.pagination.total)
                }

        })
    }
    return (
        <div className="arrdv9-0 kaxIlA">
            <Header></Header>
            <div className="sc-eCjkpP iDHATf mt-20">
                   <h3 className="cate-title">当前栏目:{name}</h3>
                <div className="sc-clhSjD yLshi">
                    {
                        list.map((item:any,index) => {
                            return (
                                <VideoCard  info={item} key={uuidv4()}></VideoCard>
                            )
                        })
                    }

                </div>
                {
                    total?<Pagination pageSize={pageSize} key={total+''+''+id} total={total}  change={getList}></Pagination>:<></>
                }
            </div>
            <Footer></Footer>
        </div>
    )
}

export default React.memo(Column)
