import React from 'react'
import {useNavigate} from "react-router-dom";
import Img from "@/components/Img";
import VideoTag from "@/components/VideoTag";
type Props = {
    info:any
}
const VideoCard= ({info}:Props) =>{
    const navigate = useNavigate()
    return (
        <div className="sc-hRxedE  MOVIE" onClick={() => {
            navigate(`/video/${info.id}`)
        }}>
            <div className="movie-content">
                <div className="mode">
                    {info.pay_mode==1?'收费':'免费'}
                </div>
                <a className="sc-hvagB dfJZUS">
                    <Img className="sc-gfarJg dJBYtv object-cover" src={info.cover_url}></Img>
                    <div className="sc-BXqHe bwSTre">
                        <span className="sc-lgqmxq FGHoO">
                    <svg aria-hidden="true" focusable="false"
                         data-prefix="fas"
                         data-icon="video"
                         className="svg-inline--fa fa-video fa-w-18 "
                         role="img"
                         xmlns="http://www.w3.org/2000/svg"
                         viewBox="0 0 576 512">
                        <path
                            fill="currentColor"
                            d="M336.2 64H47.8C21.4 64 0 85.4 0 111.8v288.4C0 426.6 21.4 448 47.8 448h288.4c26.4 0 47.8-21.4 47.8-47.8V111.8c0-26.4-21.4-47.8-47.8-47.8zm189.4 37.7L416 177.3v157.4l109.6 75.5c21.2 14.6 50.4-.3 50.4-25.8V127.5c0-25.4-29.1-40.4-50.4-25.8z"></path></svg>{info.play_time}
                </span>
                    </div>
                </a>
            </div>
            <div className="movie-text">
                <span
                    className="sc-biOYSp ecirrm">{info.title}</span><span
                className="sc-hguquU kZsUpz">
                    <VideoTag list={info.tags}></VideoTag>
                </span>
            </div>
        </div>
    )
}

export default VideoCard;
